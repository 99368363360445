import React, { Component } from 'react'
import { connect } from 'react-redux'
import Authentication from 'containers/Authentication'
import Notifications from 'react-notification-system-redux'

class App extends Component {

  state = {
    init: false,
  }

  render() {
    return (
      <Authentication>
        <div className="h-screen w-screen flex overflow-hidden bg-white">
          {this.props.children}
          <Notifications
            notifications={this.props.notifications}
            style={false}
          />
        </div>
      </Authentication>
    )
  }
}

const mapStateToProps = (state) => ({
  notifications: state.notifications,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
