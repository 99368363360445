import React from 'react'
import ManagementHeader from './ManagementHeader'
import ManagementTable from './ManagementTable'
import { requestCourseCategories } from 'actions/courses'

const CourseCategoryList = () => {
  const cols = {
    // 'id': {
    //   label: 'ID',
    //   sortable: true,
    // },
    'name_short': {
      label: 'Abkürzung',
      sortable: true,
    },
    'name': {
      label: 'Titel',
      sortable: true,
    },
    'export_section.name': {
      label: 'Export Bereich / Typ',
      sortable: true,
    },
    'export_order': {
      label: 'Export Position',
      sortable: true,
    },
  }
  return (
    <div className="management managemant--course-categories h-full overflow-hidden flex flex-col">
      <div className="flex-initial">
        <ManagementHeader type="course_categories" title="Kurskategorien" />
      </div>
      <div className="flex-1 h-full">
        <ManagementTable type="course_categories" requestItems={requestCourseCategories} cols={cols} />
      </div>
    </div>
  )
}

export default CourseCategoryList