import { createAction } from 'redux-actions'
import * as api from 'api'
import { routerActions } from 'react-router-redux'
import { getAuthState } from 'selectors/auth'

export const states = {
  AUTHENTICATED: 'AUTHENTICATED',
  NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
  UNKNOWN: 'UNKOWN',
  EXPIRED: 'EXPIRED',
}

export const changeAuth = createAction('CHANGE_AUTH')
export const logoutAction = createAction('LOGOUT')

export const checkAuth = () => async (dispatch, getState) => {
  try {
    const state = getState()
    await api.checkAuth()
    if (getAuthState(state) !== states.AUTHENTICATED) {
      dispatch(changeAuth({ state: states.AUTHENTICATED }))
    }
  } catch (error) {
    console.log(error)
    dispatch(changeAuth({ state: states.NOT_AUTHENTICATED }))
  }
}

export const login = (username, password) => async (dispatch) => {
  try {
    await api.login(username, password)
    dispatch(changeAuth({ "state": states.AUTHENTICATED }))   
  } catch (error) {
    throw Error('Fehler')
  } 
}

export const register = () => async (dispatch) => {

  dispatch(changeAuth({ state: states.AUTHENTICATED }))
  dispatch(routerActions.push('/'))
}

export const resetPassword = () => async () => {

}

export const logout = () => async (dispatch) => {
  await api.logout()
  dispatch(logoutAction())
  dispatch(routerActions.push('/'))
}
