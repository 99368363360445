import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux";
import { setQuery as storeSetQuery } from "actions/management";
import { debounce } from "lodash-es";

const ManagementHeader = (props) => {
  const { title, type, dispatch } = props;
  const className = ["managment-header", "management-header--" + type].join(
    " "
  );
  
  const [query, setQuery] = useState(props.context === type ? props.query : '');

  const onSubmitSearch = (event) => {
    event.preventDefault();
    // doSearch(query)
    return false;
  };

  const onChange = (value) => {
    setQuery(value);
    // doSearch(query)
  };

  const doSearch = debounce(function (q) {
    dispatch(storeSetQuery(q, type));
  }, 500);

  const onClickAdd = () => {
    dispatch(routerActions.push("/management/" + type + "/create"));
  };

  useEffect(() => {
    // setQuery('')
    doSearch(query);
  }, [query, doSearch]);

  return (
    <div
      className={
        className +
        " md:flex md:items-center md:justify-between px-8 py-5 bg-white shadow-lg z-10 border-b border-gray-100"
      }
    >
      <div className="flex-1 min-w-0">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate">
          {title || type}
        </h2>
      </div>
      <div className="mt-4 flex md:mt-0 md:ml-4">
        <div className="management-search bg-gray-100 border text-sm rounded-full pl-4">
          <form onSubmit={onSubmitSearch}>
            <span className="text-gray-400"><i className="fas fa-search"></i> </span>
            <input
              value={query}
              onChange={(e) => onChange(e.target.value)}
              className="bg-transparent p-2 pl-2 outline-none"
            />
          </form>
        </div>
        <div className="management-add">
          <button
            className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={onClickAdd}
          >
            Erstellen
          </button>
        </div>
      </div>
    </div>
  );
};

export default connect((state, ownProps) => {
  return {
    context: state.management.context,
    query: state.management.query,
  };
})(ManagementHeader);
