import React from 'react'
import { connect } from 'react-redux'
import { requestGrades } from 'actions/grades'
import Multiselect from './Multiselect'

const MultiselectGrade = (props) => {
  return <Multiselect
    label="Jahrgänge"
    type="grades"
    request={requestGrades}
    value={props.value}
    onChange={props.onChange}
  />
}

export default connect()(MultiselectGrade)