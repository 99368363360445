import { normalize } from 'normalizr'
import connector from './connector'
import {
  courseSchema,
  courseCategorySchema,
  courseTextblockSchema,
  coursePupilSchema,
  exportSectionSchema,
  gradeSchema,
  groupSchema,
  pupilSchema,
  ratingTypeSchema,
  reportSchema,
  settingSchema,
  teacherSchema,
  textblockSchema,
  textblockCategorySchema,
  userSchema,
  documentTypeSchema,
  exportSchema,
} from './schema'

const REACT_APP_API_ROOT = window && window.location && window.location.hostname !== 'localhost'
  ? `${window.location.origin.replace('.', '-api.')}/api/v1`
  : process.env.REACT_APP_API_ROOT
    ? process.env.REACT_APP_API_ROOT
    : '/api/v1'

// console.log({ REACT_APP_API_ROOT })

// auth + settings
export const requestUser = async () => {
  const result = await connector.request(`${REACT_APP_API_ROOT}/user/me/`)
  return normalize(result.data, userSchema)
}

export const checkAuth = () => connector.request(`${REACT_APP_API_ROOT}/user/me/`, 'GET')

export const login = async (username, password) => await connector.request(`${REACT_APP_API_ROOT}/login/`, 'POST', { username, password })

export const logout = () => connector.request(`${REACT_APP_API_ROOT}/logout/`)

export const requestSettings = async () => {
  const result = await connector.request(`${REACT_APP_API_ROOT}/settings/`)
  return normalize(result.objects, [settingSchema])
}


// teachers
export const requestTeachers = async () => {
  // console.log("requestTeachers")
  const result = await connector.request(`${REACT_APP_API_ROOT}/teachers/`)
  // console.log({ result },normalize(result.objects, [teacherSchema]))
  return normalize(result.objects, [teacherSchema])
}

export const requestTeacher = async (id) => {
  const result = await connector.request(`${REACT_APP_API_ROOT}/teachers/${id}/`)
  // console.log({ result },normalize(result.data, [teacherSchema]))
  return normalize(result.data, teacherSchema)
}

export const postTeacher = async (data) => {
  const route = data.id
    ? `${REACT_APP_API_ROOT}/teachers/${data.id}/`
    : `${REACT_APP_API_ROOT}/teachers/`
  const result = await connector.request(route, data.id ? 'PATCH' : 'POST', data)
  return normalize(result.data, teacherSchema)
}

export const deleteTeacher = async data => connector.request(`${REACT_APP_API_ROOT}/teachers/${data.id}/`, 'DELETE', data)


// pupils
export const requestPupils = async () => {
  const result = await connector.request(`${REACT_APP_API_ROOT}/pupils/`)
  return normalize(result.objects, [pupilSchema])
}

export const requestPupil = async (pupilId) => {
  const result = await connector.request(`${REACT_APP_API_ROOT}/pupils/${pupilId}/`)
  return normalize(result.data, pupilSchema)
}

export const postPupil = async (data) => {
  const route = data.id
    ? `${REACT_APP_API_ROOT}/pupils/${data.id}/`
    : `${REACT_APP_API_ROOT}/pupils/`
  const result = await connector.request(route, data.id ? 'PATCH' : 'POST', data)
  return normalize(result.data, pupilSchema)
}

export const deletePupil = async data => connector.request(`${REACT_APP_API_ROOT}/pupils/${data.id}/`, 'DELETE', data)


// grades
export const requestGrades = async () => {
  const result = await connector.request(`${REACT_APP_API_ROOT}/grades/`)
  return normalize(result.objects, [gradeSchema])
}

export const postGrade = async (data) => {
  const route = data.id
    ? `${REACT_APP_API_ROOT}/grades/${data.id}/`
    : `${REACT_APP_API_ROOT}/grades/`
  const result = await connector.request(route, data.id ? 'PATCH' : 'POST', data)
  return normalize(result.data, gradeSchema)
}

export const deleteGrade = async data => connector.request(`${REACT_APP_API_ROOT}/grades/${data.id}/`, 'DELETE', data)


// groups
export const requestGroups = async () => {
  const result = await connector.request(`${REACT_APP_API_ROOT}/groups/`)
  return normalize(result.objects, [groupSchema])
}

export const requestGroup = async (id) => {
  const result = await connector.request(`${REACT_APP_API_ROOT}/groups/${id}/`)
  return normalize(result.data, groupSchema)
}

export const postGroup = async (data) => {
  const route = data.id
    ? `${REACT_APP_API_ROOT}/groups/${data.id}/`
    : `${REACT_APP_API_ROOT}/groups/`
  const result = await connector.request(route, data.id ? 'PATCH' : 'POST', data)
  return normalize(result.data, groupSchema)
}

export const deleteGroup = async data => connector.request(`${REACT_APP_API_ROOT}/groups/${data.id}/`, 'DELETE', data)


// textblock categories
export const requestTextblockCategories = async () => {
  const result = await connector.request(`${REACT_APP_API_ROOT}/textblock_categories/`)
  return normalize(result.objects, [textblockCategorySchema])
}

export const requestTextblockCategory = async (id) => {
  const result = await connector.request(`${REACT_APP_API_ROOT}/textblock_categories/${id}/`)
  return normalize(result.data, textblockCategorySchema)
}

export const postTextblockCategory = async (data) => {
  const route = data.id
    ? `${REACT_APP_API_ROOT}/textblock_categories/${data.id}/`
    : `${REACT_APP_API_ROOT}/textblock_categories/`
  const result = await connector.request(route, data.id ? 'PATCH' : 'POST', data)
  return normalize(result.data, textblockCategorySchema)
}

export const deleteTextblockCategory = async data => connector.request(`${REACT_APP_API_ROOT}/textblock_categories/${data.id}/`, 'DELETE', data)


// textblocks
export const requestTextblocks = async () => {
  const result = await connector.request(`${REACT_APP_API_ROOT}/textblocks/`)
  return normalize(result.objects, [textblockSchema])
}

export const requestTextblock = async (id) => {
  const result = await connector.request(`${REACT_APP_API_ROOT}/textblocks/${id}/`)
  return normalize(result.data, textblockSchema)
}

export const postTextblock = async (data) => {
  const route = data.id
    ? `${REACT_APP_API_ROOT}/textblocks/${data.id}/`
    : `${REACT_APP_API_ROOT}/textblocks/`
  const result = await connector.request(route, data.id ? 'PATCH' : 'POST', data)
  return normalize(result.data, textblockSchema)
}

export const deleteTextblock = async data => connector.request(`${REACT_APP_API_ROOT}/textblocks/${data.id}/`, 'DELETE', data)







export const requestCourses = async () => {
  const result = await connector.request(`${REACT_APP_API_ROOT}/courses/`)
  return normalize(result.objects, [courseSchema])
}

export const requestCourse = async (id) => {
  const result = await connector.request(`${REACT_APP_API_ROOT}/courses/${id}/`)
  return normalize(result.data, courseSchema)
}

export const postCourse = async (data) => {
  const route = data.id
    ? `${REACT_APP_API_ROOT}/courses/${data.id}/`
    : `${REACT_APP_API_ROOT}/courses/`
  const result = await connector.request(route, data.id ? 'PATCH' : 'POST', data)
  return normalize(result.data, courseSchema)
}

export const deleteCourse = async data => {
  return connector.request(`${REACT_APP_API_ROOT}/courses/${data.id}/`, 'DELETE', data)
}


export const requestCourseCategories = async () => {
  const result = await connector.request(`${REACT_APP_API_ROOT}/course_categories/`)
  return normalize(result.objects, [courseCategorySchema])
}

export const requestCourseCategory = async (id) => {
  const result = await connector.request(`${REACT_APP_API_ROOT}/course_categories/${id}/`)
  return normalize(result.data, courseCategorySchema)
}

export const postCourseCategory = async (data) => {
  const route = data.id
    ? `${REACT_APP_API_ROOT}/course_categories/${data.id}/`
    : `${REACT_APP_API_ROOT}/course_categories/`
  const result = await connector.request(route, data.id ? 'PATCH' : 'POST', data)
  return normalize(result.data, courseCategorySchema)
}

export const deleteCourseCategory = async data => {
  return connector.request(`${REACT_APP_API_ROOT}/course_categories/${data.id}/`, 'DELETE', data)
}


export const requestExportSections = async () => {
  const result = await connector.request(`${REACT_APP_API_ROOT}/export_sections/`)
  return normalize(result.objects, [exportSectionSchema])
}


export const requestRatingTypes = async () => {
  const result = await connector.request(`${REACT_APP_API_ROOT}/rating_types/`)
  return normalize(result.objects, [ratingTypeSchema])
}



export const requestCoursePupils = async (id) => {
  const result = await connector.request(`${REACT_APP_API_ROOT}/courses/${id}/pupils/`)
  return normalize(result.objects, [coursePupilSchema])
}

export const requestCourseReports = async (id) => {
  const result = await connector.request(`${REACT_APP_API_ROOT}/courses/${id}/reports/`)
  return normalize(result.objects, [reportSchema])
}

export const postCourseReports = async (courseId, reports) => {
  const result = await connector.request(`${REACT_APP_API_ROOT}/courses/${courseId}/reports/`, 'PATCH', reports)
  return normalize(result.objects, [reportSchema])
}

export const requestCourseTextblocks = async (id) => {
  const result = await connector.request(`${REACT_APP_API_ROOT}/courses/${id}/textblocks/`)
  return normalize(result.objects, [courseTextblockSchema])
}


export const requestExports = async () => {
  const result = await connector.request(`${REACT_APP_API_ROOT}/exports/`)
  return normalize(result.objects, [exportSchema])
}

export const postExports = async (exports) => {
  const result = await connector.request(`${REACT_APP_API_ROOT}/exports/`, 'POST', exports)
  return normalize(result.data, [exportSchema])
}

export const requestDocumentTypes = async () => {
  const result = await connector.request(`${REACT_APP_API_ROOT}/document_types/`)
  // console.log({ result })
  return normalize(result.objects, [documentTypeSchema])
}