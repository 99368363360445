import React from 'react'

const RatingsCoursePupils = (props) => {
  const { pupils, activeOffset } = props
  if (!pupils) {
    return null
  }

  const pupilsByGroup = Object.keys(pupils).reduce((acc, pupilId) => {
    const pupil = pupils[pupilId]
    const { group } = pupil
    if (group) {
      if (!acc[group.id]) {
        acc[group.id] = {
          ...group,
          pupils: [],
        }
      }
      acc[group.id].pupils.push(pupil)
    }
    return acc
  }, {})

  let counter = 0

  // todo: multiple groups
  return <section>
    {Object.keys(pupilsByGroup)
      .map(groupId => pupilsByGroup[groupId])
      .map(({ id, name, pupils }) => (
        <div key={id}>
          <h3 className="text-xs text-gray-400 border- border-white border-opacity-25 py-3 pb-2 pt-4 font-medium tracking-wider">
            {name}
          </h3>
          <ul className="pb-2">
            {pupils.map((pupil) => {
              const report = props.reports
                ? props.reports.find(report => report.pupil_id === pupil.id)
                : null
              
              const reportIsEmpty = report
                && report.absence_days === "0"
                && report.absence_days_unexcused === "0"
                && report.absence_hours === "0"
                && report.absence_hours_delayed === "0"
                && report.absence_hours_unexcused === "0"
                && report.certificates_level_1 === "0"
                && report.certificates_level_2 === "0"
                && report.certificates_level_3 === "0"
                && report.certificates_possible === "0"
                && report.textblock_ids.length === 0
                && (report.text === null || report.text === "")

              const className = activeOffset === counter++ ? 'bg-gray-200 text-gray-600' : ''

              return <li key={pupil.id}>
                <button
                  className={`${className} flex text-xs justify-stretch w-full items-center p-3 px-2 text-gray-600 hover:bg-gray-200 hover:no-underline rounded-md cursor-pointer font-medium focus:outline-none`}
                  onClick={() => props.onClickPupil(pupil)}
                >
                  <span className="flex-1 text-left">{pupil.last_name}, {pupil.first_name}</span>
                  {reportIsEmpty && <span className="w-2 h-2 rounded-full bg-yellow-500"></span>}
                </button>
              </li>
            })
            }
          </ul>
        </div>
      ))}
  </section>
}

export default RatingsCoursePupils