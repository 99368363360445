import React from "react";

const Switch = (props) => {
  const onClick = () => {
    if (props.onChange) {
      props.onChange(!props.value);
    }
  };

  const className1 =
    "flex w-8 text-xs h-5 my-3 mr-2 focus:outline-none cursor-pointer rounded-full " +
    (props.value ? "bg-blue-600 justify-end" : "bg-gray-600 justify-start");
  const className2 =
    "w-5 h-5 text-xs rounded-full bg-white border " +
    (props.value ? "border-blue-600" : "border-gray-600");

  return (
    <div className="flex flex-col">
      {props.label && (
        <label className="py-2 block text-sm font-medium text-gray-700">
          {props.label}
        </label>
      )}
      <div className="flex justify-start items-center">
        <button type="button" className={className1} onClick={onClick}>
          <div className={className2}></div>
        </button>
        <span className="text-sm">{props.info}</span>
      </div>
    </div>
  );
};

export default Switch;
