import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { isEqual } from "lodash-es";

const RatingButtonsMulti = (props) => {
  const {
    groupedTextblocks,
    textblocks,
    categories,
    ratings,
    report,
    onChange,
  } = props;

  const [_ratings, setRatings] = useState(ratings);

  const onClick = (categoryId, textblockId) => {
    const currentSelection = _ratings[categoryId] || [];
    const updatedSelection = currentSelection.includes(textblockId)
      ? currentSelection.filter((id) => id !== textblockId) // Deselect if already selected
      : [...currentSelection, textblockId]; // Select if not already selected

    const updatedRatings = {
      ..._ratings,
      [categoryId]: updatedSelection,
    };

    setRatings(updatedRatings);

    if (onChange) {
      const textblock_ids = Object.keys(updatedRatings)
        .reduce((acc, cid) => [...acc, ...updatedRatings[cid]], [])
        .sort();

      onChange({
        ...report,
        textblock_ids,
      });
    }
  };

  useEffect(() => {
    if (!isEqual(ratings, _ratings)) {
      setRatings(ratings);
      if (onChange) {
        const textblock_ids = Object.keys(ratings)
          .reduce((acc, cid) => [...acc, ...ratings[cid]], [])
          .sort();
        onChange({
          ...report,
          textblock_ids,
        });
      }
    }
  }, [_ratings, ratings, onChange]);

  return (
    <div className="">
      {Object.keys(groupedTextblocks).length === 0 && (
        <div className="italic text-gray-500">
          Es sind keine Bewertungstexte zugeordnet.
        </div>
      )}
      {Object.keys(groupedTextblocks).map((groupId) => {
        const group = groupedTextblocks[groupId];
        return (
          <div className="" key={groupId}>
            <label className="py-2 block text-sm font-medium text-gray-700">
              {group.category.name}
            </label>
            {group.textblocks
              .sort((a, b) => {
                if (!isNaN(a.rating) || !isNaN(b.rating)) {
                  if (a.rating > b.rating) {
                    return -1;
                  }
                  if (a.rating < b.rating) {
                    return 1;
                  }
                }
                return a.text > b.text ? 1 : -1;
              })
              .map((textblock) => {
                const categoryId = group.category.id;
                const isSelected =
                  _ratings[categoryId] &&
                  _ratings[categoryId].includes(textblock.id);
                let className = isSelected
                  ? "bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 mr-3 mb-1 block text-sm rounded-md border border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 font-medium text-left"
                  : "bg-blue-100 hover:bg-blue-200 text-blue-600 py-2 px-4 mr-3 mb-1 block text-sm rounded-md border border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 font-medium text-left";
                return (
                  <button
                    key={textblock.id}
                    onClick={() => onClick(categoryId, textblock.id)}
                    className={className}
                  >
                    {textblock.text}
                  </button>
                );
              })}
          </div>
        );
      })}
      <div className="mt-12">
        <label className="py-2 block text-sm font-medium text-gray-700">
          Vorschau
        </label>
        <div className="p-2 bg-white shadow-lg border border-gray-200 rounded">
          <div className="text-gray-500 text-sm p-2">
            {Object.keys(_ratings)
              .reduce((acc, categoryId) => {
                return _ratings[categoryId].reduce((acc, textblockId) => {
                  const textblock = textblocks.find(
                    (t) => t.id === textblockId
                  );
                  const category = categories.find(
                    (c) => c.id === parseInt(categoryId)
                  );
                  acc.push({
                    textblock,
                    category,
                  });
                  return acc;
                }, acc);
              }, [])
              .sort((a, b) => {
                if (!a.category || !b.category) {
                  return 0;
                }
                if (
                  a.category.export_order === b.category.export_order
                ) {
                  return a.category.name > b.category.name ? 1 : -1;
                }
                return a.category.export_order > b.category.export_order
                  ? 1
                  : -1;
              })
              .map((rating, index) => {
                return rating && rating.textblock ? (
                  <div
                    key={index}
                    className="inline-block mr-1 mb-1 bg-gray-200 text-black p-1 px-2 rounded"
                  >
                    {rating.textblock.text}
                  </div>
                ) : null;
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect((state, ownProps) => {
  const groupedTextblocks = ownProps.textblocks
    .reduce((acc, textblock) => {
      return [textblock.textblock_category_id].reduce((acc, cid) => {
        const category = ownProps.textblockCategories.find(
          (c) => c.id === cid
        );
        if (category) {
          if (!acc[category.id]) {
            acc[category.id] = {
              category,
              textblocks: [textblock],
            };
          } else {
            acc[category.id].textblocks.push(textblock);
          }
        }
        return acc;
      }, acc);
    }, [])
    .sort((a, b) => {
      if (!a.category || !b.category) {
        return 0;
      }
      if (a.category.export_order === b.category.export_order) {
        return a.category.name > b.category.name ? 1 : -1;
      }
      return a.category.export_order > b.category.export_order ? 1 : -1;
    });

  const categories = Object.keys(groupedTextblocks).map(
    (categoryId) => groupedTextblocks[categoryId].category
  );

  const {
    report: { textblock_ids },
  } = ownProps;

  const ratings = Object.keys(groupedTextblocks).reduce((acc, groupId) => {
    const { category, textblocks } = groupedTextblocks[groupId];
    textblocks.forEach((textblock) => {
      if (textblock_ids.includes(textblock.id)) {
        if (!acc[category.id]) {
          acc[category.id] = [textblock.id];
        } else {
          acc[category.id].push(textblock.id);
        }
      }
    });
    return acc;
  }, {});

  return {
    groupedTextblocks,
    categories,
    ratings,
  };
})(RatingButtonsMulti);
