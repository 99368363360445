import React from 'react'
import InputInt from 'components/form/InputInt'

const RatingCertificates = (props) => {
  const onChange = (key, value) => {
    if (props.onChange) {
      props.onChange({
        ...props.report,
        [key]: value === null || isNaN(parseInt(value)) ? 0 : parseInt(value),
      })
    }
  }
  const data = {
    certificates_possible: "Erreichbar",
    certificates_level_1: "Basis",
    certificates_level_2: "Basis-Plus",
    certificates_level_3: "Experten",
  }
  return <div className="">
    <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-10">
      {Object.keys(data).map(key =>
        <div key={key} className="sm:col-span-2">
          <InputInt label={data[key]} value={props.report[key]} onChange={(value) => onChange(key, value)} required={false} name={"" + props.report.id + '-' + key}/>
        </div>
      )}
    </div>
  </div>
}

export default RatingCertificates