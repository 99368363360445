import React from 'react'
import RatingAbsenceHours from './RatingAbsenceHours'
import RatingCertificates from './RatingCertificates'
import RatingButtonsMulti from './RatingButtonsMulti'
// import RatingButtonsMultiWithText from './RatingButtonsMultiWithText'
// import RatingButtonsMultiHorizontal from './RatingButtonsMultiHorizontal'
import RatingButtonsSingle from './RatingButtonsSingle'
import RatingButtonsSingleWithText from './RatingButtonsSingleWithText'
import RatingButtonsSingleHorizontal from './RatingButtonsSingleHorizontal'
import RatingText from './RatingText'

const Rating = (props) => {
  const map = {
    'ABSENCE_HOURS': RatingAbsenceHours,
    'CERTIFICATES': RatingCertificates,
    'BUTTONS_MULTI': RatingButtonsMulti,
    // 'BUTTONS_MULTI_TEXT': RatingButtonsMultiWithText,
    // 'BUTTONS_MULTI_HORIZONTAL': RatingButtonsMultiHorizontal,
    'BUTTONS_SINGLE': RatingButtonsSingle,
    'BUTTONS_SINGLE_TEXT': RatingButtonsSingleWithText,
    'BUTTONS_SINGLE_HORIZONTAL': RatingButtonsSingleHorizontal,
    'TEXT': RatingText,
  }
  const Component = map[props.type.toUpperCase()];
  if (!Component) {
    return <div>unknown rating type {props.type}</div>;
  }
  return <Component {...props} />
}

export default Rating