import React from 'react'
import { connect } from 'react-redux'
import i18n from 'i18n.json'
import SelectWrapper from './SelectWrapper'

const SelectGender = (props) => {

  const genders = [
    { id: 'MALE', name: i18n[`gender.MALE`] },
    { id: 'FEMALE', name: i18n[`gender.FEMALE`] },
    { id: 'DIVERSE', name: i18n[`gender.DIVERSE`] },
  ]

  return (
    <div className="select select--gender">
      <label className="py-2 block text-sm font-medium text-gray-700">{props.label || "Geschlecht"}</label>
      <SelectWrapper>
        <select
          onChange={(e) => props.onChange(e.target.value)}
          value={props.value || ""}
          className="shadow-sm block w-full border-gray-300 rounded-md outline-none border appearance-none"
        >
          <option value="" disabled>Auswählen...</option>
          {genders.sort((t1, t2) => {
            return t1.name > t2.name ? 1 : -1
          }).map(gender => {
            return <option
              key={gender.id}
              value={gender.id}
            >
              {gender.name}
            </option>
          })}
        </select>
      </SelectWrapper>
    </div>
  )
}

export default connect()(SelectGender)