import React from 'react'
import { connect } from 'react-redux'
import { requestCourseCategories } from 'actions/courses'
import Multiselect from './Multiselect'

const MultiselectCourseCategory = (props) => {
  return <Multiselect
    label="Kurskategorien"
    type="course_categories"
    request={requestCourseCategories}
    renderName={(courseCategory) => `${courseCategory.name} (${courseCategory.name_short})`}
    value={props.value}
    onChange={props.onChange}
  />
}

export default connect()(MultiselectCourseCategory)