import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux";
import { isEqual } from "lodash-es";
import {
  requestCourse,
  postCourse as postData,
  deleteCourse as deleteData,
} from "actions/courses";
import { allSettingsSelector } from 'selectors/settings'
import Input from "../form/Input";
import SelectCourseCategory from "../form/SelectCourseCategory";
import SelectRatingType from "../form/SelectRatingType";
import MultiselectPupil from "../form/MultiselectPupil";
import MultiselectTeacher from "../form/MultiselectTeacher";
import ManagementSubmit from "./ManagementSubmit";
import IsDirtyHelper from "./IsDirtyHelper";
import ChildCourseForm from "./ChildCourseForm";
import InputInt from "components/form/InputInt";

const CourseForm = (props) => {
  const className = [
    "managment-detail",
    "management-detail--" + props.type,
  ].join(" ");

  const initialData = useMemo(
    () => ({
      id: null,
      name: "",
      teacher_ids: [],
      pupil_ids: [],
      course_category_id: null,
      rating_type_id: null,
      rating_max_length: null,
      child_courses: [],
    }),
    []
  );

  const [data, setData] = useState();
  const [original, setOriginal] = useState();
  const [isDirty, setIsDirty] = useState(false);
  const [isVisible, setIsVisible] = useState(false);


  const {
    params: { id },
    dispatch,
    course,
  } = props;

  useEffect(() => {
    if (!data && id) {
      dispatch(requestCourse(id));
    }
  }, [id, data, dispatch]);

  useEffect(() => {
    if (!id) {
      setData(initialData);
    }
  }, [id, initialData]);

  useEffect(() => {
    const o = course
      ? {
          id: course.id,
          name: course.name,
          teacher_ids: course.teacher_ids,
          pupil_ids: course.pupil_ids,
          course_category_id: course.course_category
            ? course.course_category.id
            : null,
          rating_type_id: course.rating_type ? course.rating_type.id : null,
          rating_max_length: course.rating_max_length,
          child_courses: course.child_courses,
        }
      : initialData;
    setOriginal(o);
    setData({ ...o });
  }, [course, initialData]);

  const setDataProp = (key, value) => {
    const d = {
      ...data,
      [key]: value,
    };
    setData(d);
    setIsDirty(!isEqual(original, d));
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  }

  if (!data) {
    return null;
  }

  const _postData = (data) => (dispatch) => {
    return dispatch(postData(data)).then(() => {
      setIsDirty(false);
      setOriginal(data);
    });
    // .catch(() => {
    //   setIsDirty(true)
    // })
  };

  const onClickBack = () => {
    dispatch(routerActions.push("/management/" + props.type));
  };

  const onClickReset = () => {
    setData(initialData);
  };

  const onChangeChildCourse = (index, course) => {
    const childCourses = data.child_courses.map((c, i) => {
      return i === index ? course : c;
    });
    // console.log({ index, course, childCourses });
    setDataProp("child_courses", childCourses);
  };

  const onAddChildCourse = (course) => {
    const childCourses = [...data.child_courses, course];
    setDataProp("child_courses", childCourses);
  };

  const onDeleteChildCourse = (index) => {
    const childCourses = [
      ...data.child_courses.splice(0, index),
      ...data.child_courses.splice(index + 1),
    ];
    setDataProp("child_courses", childCourses);
  };

  return (
    <div className={className + " flex flex-col h-full overflow-hidden"}>
      <div className="md:flex md:items-center md:justify-between px-8 py-5 bg-white shadow-md z-10 border-b border-gray-100">
        <button
          type="button"
          className=" inline-flex p-2 mr-3 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          onClick={onClickBack}
        >
          <i className="fal fa-long-arrow-left" />
        </button>
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl inline-flex font-bold leading-7 text-gray-900 sm:truncate">
            {id ? data.name : "Kurs hinzufügen"}
          </h2>
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <ManagementSubmit
            create={data.id === null}
            isDirty={isDirty}
            data={data}
            post={_postData}
            delete={deleteData}
            onClickBack={onClickBack}
            onClickReset={onClickReset}
          />
        </div>
      </div>

      <div className="management-form flex-1 h-full overflow-auto bg-gray-100 p-12">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="grid grid-cols-3 gap-x-12 gap-y-4 mb-10 pb-10 border-b border-gray-300">
            <div className="col-span-3">
              <h3 className="text-lg font-medium leading-6 text-gray-900">1. Kursinformationen & Beurteilungsmethoden</h3>
              <p className="mt-1 max-w-4xl text-sm text-gray-500">
                <i className="far fa-lightbulb-on text-yellow-500"></i> Geben Sie einen <span className="font-semibold">Kurstitel</span> sowie die zugehörige <span className="font-semibold">Kurskategorie (Fachbereich)</span> ein und wählen Sie die gewünschte <span className="font-semibold">Beurteilungsmethode</span> aus. Falls Sie "Textbausteine" als Beurteilungsmethode nutzen, steuert die Auswahl der Kurskategorie, welche Textbausteine für diesen Kurs zur Auswahl stehen.
              </p>
            </div>
            <div className="col-span-3">
              <Input
                label="Kurstitel *"
                placeholder="Bsp: Deutsch 3a"
                value={data.name}
                onChange={(value) => {
                  setDataProp("name", value);
                  data.child_courses.map((child_course) => {
                    child_course.name = value
                    return child_course
                  });

                }}
              />
            </div>
            <SelectRatingType
              label="Beurteilungsmethode *"
              value={data.rating_type_id}
              onChange={(value) => setDataProp("rating_type_id", value)}
            />
            <SelectCourseCategory
              label="Kurskategorie / Fachbereich *"
              value={data.course_category_id}
              onChange={(value) => setDataProp("course_category_id", value)}
            />
            <InputInt
              label="Maximale Zeichenlänge (optional)"
              placeholder={`Standard: ${props.config.rating_max_length} Zeichen`}
              nullable={true}
              value={data.rating_max_length}
              onChange={(value) => setDataProp("rating_max_length", value)}
            />
            <div className="col-span-3">
              <div>
                {data.child_courses &&
                  data.child_courses.map((course,  index) => {
                    return (
                      <div className="mb-6">
                      <ChildCourseForm
                        key={index}
                        course={course}
                        label={"Beurteilungsmethode " + (index + 2)}
                        placeholderLength={`Standard: ${props.config.rating_max_length} Zeichen`}
                        onChange={(value) => onChangeChildCourse(index, value)}
                        onDelete={() => onDeleteChildCourse(index)}
                      /></div>
                    );
                  })}
                  <p className="mt-1 max-w-4xl text-sm text-gray-500 mb-2">
                    <i className="far fa-lightbulb-on text-yellow-500"></i> Falls Sie weitere Beurteilungsmethoden für verschiedene Dokumente benötigen (bspw. Noten für ein Notenzeugnis und Textbausteine für einen Kompetenzbogen/ASV), ergänzen Sie weitere Beurteilungsmethoden:
                  </p>
                  {!isVisible && (
                    <div>
                      <button onClick={toggleVisibility} type="button" className="inline-flex items-center rounded-md border border-gray-300 px-4 py-2 text-base font-medium text-blue-700 hover:bg-gray-200 hover:text-blue-800">+ Beurteilungsmethode hinzufügen</button>
                    </div>
                  )}

                  {isVisible &&
                    <div className="w-full">
                      <ChildCourseForm
                        course={null}
                        label={"Weitere Beurteilungsmethode *"}
                        placeholderLength={`Standard: ${props.config.rating_max_length} Zeichen`}
                        onAdd={(course) => onAddChildCourse(course)}
                        toggleVisibility={toggleVisibility}
                      />
                    </div>}
              </div>
            </div>
          </div>

          <div className="mb-10 pb-10 border-b border-gray-300">
              <h3 className="text-lg font-medium leading-6 text-gray-900">2. Lehrkräfte zuordnen</h3>
              <p className="mt-1 mb-4 max-w-4xl text font text-gray-500">
                <i className="far fa-lightbulb-on text-yellow-500"></i> Weisen Sie dem Kurs beliebig viele <span className="font-semibold">Lehrkräfte</span> zu. Zugewiesene Lehrkräfte können diesen Kurs sehen und bearbeiten. Beachten Sie, dass pro Kurs und Schüler/in nur eine Beurteilung gespeichert wird!
              </p>
            <MultiselectTeacher
              value={data.teacher_ids}
              onChange={(value) => setDataProp("teacher_ids", value)}
            />
          </div>

          <div className="mb-10">
              <h3 className="text-lg font-medium leading-6 text-gray-900">3. Schüler*innen zuordnen</h3>
              <p className="mt-1 mb-4 max-w-4xl text-sm text-gray-500">
                <i className="far fa-lightbulb-on text-yellow-500"></i> Weisen Sie dem Kurs beliebig viele <span className="font-semibold">Schüler*innen</span> zu. Zugewiesene Schüler*innen können in diesem Kurs beurteilt werden. Sie können Schüler*innen entsprechend der Klassenstruktur oder aber auch Klassen- und Jahrgangsübergreifend hinzufügen.
              </p>
            <MultiselectPupil
              value={data.pupil_ids}
              onChange={(value) => setDataProp("pupil_ids", value)}
            />
          </div>

          {isDirty && (
            <IsDirtyHelper
              route={props.route}
              original={original}
              data={data}
            />
          )}

          {/* <pre>
            {JSON.stringify(data, null, 2)}
          </pre> */}
        </form>
      </div>
    </div>
  );
};

export default connect((state, ownProps) => {
  const course = state.entities.courses
    ? state.entities.courses[ownProps.params.id]
    : null;
  return {
    type: "courses",
    course,
    courses: state.entities.courses,
    grades: state.entities.grades,
    config: allSettingsSelector(state),
  };
})(CourseForm);
