import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import TextareaAutosize from 'react-textarea-autosize'

const Textarea = (props) => {
  const charCount = props.charCount || 0
  const [value, setValue] = useState(props.value || "")
  const onChange = (event) => {
    let { value } = event.target
    if (props.maxLength && value.length > props.maxLength - charCount) {
      value = value.substr(0, props.maxLength - charCount)
    }
    setValue(value)
    props.onChange(value)
  }

  useEffect(() => {
    if (props.value !== value) {
      setValue(props.value)
    }
  }, [props.value])

  return (
    <div className="input">
      {props.label &&
        <label className="py-2 block text-sm font-medium text-gray-700">
          {props.label}
        </label>
      }
      <TextareaAutosize
        onChange={onChange}
        value={value}
        placeholder={props.placeholder || ""}
        className={props.className || "shadow-sm block w-full border-gray-300 rounded-md resize-none"}
      />
      {props.maxLength &&
        <span className="block w-full text-right text-gray-500 text-xs">
          {value ? (value.length + charCount) : charCount} / {props.maxLength}
        </span>
      }
    </div>
  )
}

export default connect()(Textarea)
