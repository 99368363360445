import * as realApi from "api";
// import fakeApi from './_fakeApi'
import { receiveEntities, removeEntity } from "actions/entities";

// const useFakeApi = process.env.REACT_APP_USE_MOCK_DATA // window && window.location && window.location.hostname === 'localhost'

const api = realApi; // useFakeApi ? fakeApi : realApi

export const requestTextblocks = () => async (dispatch) => {
  const payload = await api.requestTextblocks();
  // console.log({ payload })
  return dispatch(receiveEntities(payload));
};

export const requestTextblock = (id) => async (dispatch) => {
  const payload = await api.requestTextblock(id);
  // console.log({ payload })
  return dispatch(receiveEntities(payload));
};

export const postTextblock = (data) => async (dispatch) => {
  const payload = await api.postTextblock(data);
  // console.log({ payload })
  await dispatch(receiveEntities(payload));
  return payload;
};

export const deleteTextblock = (data) => (dispatch) => {
  return api
    .deleteTextblock(data)
    .then(() => dispatch(removeEntity({ entityType: "textblocks", ...data })));
};
