import * as realApi from "api";
// import fakeApi from './_fakeApi'
import { receiveEntities, removeEntity } from "actions/entities";

// const useFakeApi = process.env.REACT_APP_USE_MOCK_DATA // window && window.location && window.location.hostname === 'localhost'

const api = realApi; // useFakeApi ? fakeApi : realApi

export const requestTeachers = () => async (dispatch) => {
  const payload = await api.requestTeachers();
  return dispatch(receiveEntities(payload));
};

export const requestTeacher = (id) => async (dispatch) => {
  const payload = await api.requestTeacher(id);
  return dispatch(receiveEntities(payload));
};

export const requestTeachersByIds = (ids) => async (dispatch) => {
  const payload = await api.requestTeachersByIds(ids);
  return dispatch(receiveEntities(payload));
};

export const postTeacher = (data) => async (dispatch) => {
  const payload = await api.postTeacher(data);
  await dispatch(receiveEntities(payload));
  return payload;
};

export const deleteTeacher = (data) => (dispatch) =>
  api
    .deleteTeacher(data)
    .then(() => dispatch(removeEntity({ entityType: "teachers", ...data })));
