import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { requestRatingTypes } from 'actions/courses'
import SelectWrapper from './SelectWrapper'

const SelectRatingType = (props) => {
  const { ratingTypes, dispatch, label } = props
  const [init, setInit] = useState(false)

  useEffect(() => {
    if (!init && (!ratingTypes || ratingTypes.length === 0)) {
      setInit(true)
      dispatch(requestRatingTypes())
    }
  }, [ratingTypes, dispatch, init, setInit])

  return (
    <div className="select select--rating-type">
      <label className="py-2 block text-sm font-medium text-gray-700">{label || "Beurteilungstyp"}</label>
      <SelectWrapper>
        <select
          onChange={(e) => props.onChange(parseInt(e.target.value))}
          value={props.value || ""}
          className="shadow-sm block w-full border-gray-300 rounded-md outline-none border appearance-none"
        >
          <option value="" disabled>Auswählen...</option>
          {props.ratingTypes.sort((t1, t2) => {
            return t1.name > t2.name ? 1 : -1
          }).map(ratingType => {
            return ratingType.description
              ? <option
                key={ratingType.id}
                value={ratingType.id}
              >
                {ratingType.name} - {ratingType.description}
              </option>
              : <option
                key={ratingType.id}
                value={ratingType.id}
              >
                {ratingType.name}
              </option>
          })}
        </select>
      </SelectWrapper>
    </div>
  )
}

export default connect((state) => {
  const ratingTypes = state.entities && state.entities.rating_types
    ? Object.keys(state.entities.rating_types).map((key) => {
      return state.entities.rating_types[key]
    })
    : []
  return {
    ratingTypes,
  }
})(SelectRatingType)