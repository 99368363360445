import React from 'react'
import { connect } from 'react-redux'
import { requestCourses } from 'actions/courses'
import Multiselect from './Multiselect'

const MultiselectCourse = (props) => {
  return <Multiselect
    label="Kurse"
    type="courses"
    request={requestCourses}
    value={props.value}
    onChange={props.onChange}
  />
}

export default connect()(MultiselectCourse)