import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux";
import { isEqual } from "lodash-es";
import {
  requestPupil,
  postPupil as postData,
  deletePupil as deleteData,
} from "actions/pupils";
import Input from "../form/Input";
import InputDate from "../form/InputDate";
import Switch from "../form/Switch";
import SelectGender from "../form/SelectGender";
import SelectGroup from "../form/SelectGroup";
import MultiselectCourse from "../form/MultiselectCourse";
import ManagementSubmit from "./ManagementSubmit";
import IsDirtyHelper from "./IsDirtyHelper";

const PupilForm = (props) => {
  const className = [
    "managment-detail",
    "management-detail--" + props.type,
  ].join(" ");

  const initialData = useMemo(
    () => ({
      id: null,
      external_id: "",
      gender: null,
      first_name: "",
      last_name: "",
      call_name: "",
      birthday: "",
      place_of_birth: "",
      school_attendance_year: "",
      is_promotion: true,
      group_id: null,
      course_ids: [],
    }),
    []
  );

  const [data, setData] = useState();
  const [original, setOriginal] = useState();
  const [isDirty, setIsDirty] = useState(false);

  const {
    params: { id },
    dispatch,
    pupil,
  } = props;

  useEffect(() => {
    if (!data && id) {
      dispatch(requestPupil(id));
    }
  }, [id, data, dispatch]);

  useEffect(() => {
    if (!id) {
      setData(initialData);
    }
  }, [id, initialData]);

  useEffect(() => {
    const o = pupil
      ? {
        id: pupil.id,
        external_id: pupil.external_id,
        gender: pupil.gender,
        first_name: pupil.first_name,
        last_name: pupil.last_name,
        call_name: pupil.call_name,
        birthday: pupil.birthday,
        place_of_birth: pupil.place_of_birth,
        school_attendance_year: pupil.school_attendance_year,
        is_promotion: pupil.is_promotion,
        group_id: pupil.group ? pupil.group.id : null,
        course_ids: pupil.course_ids,
      }
      : initialData;
    setOriginal(o);
    setData({ ...o });
  }, [pupil, initialData]);

  const setDataProp = (key, value) => {
    const d = {
      ...data,
      [key]: value,
    };
    setData(d);
    setIsDirty(!isEqual(original, d));
  };

  if (!data) {
    return null;
  }

  const _postData = (data) => (dispatch) => {
    return dispatch(postData(data)).then(() => {
      setIsDirty(false);
      setOriginal(data);
    });
    // .catch(() => {
    //   setIsDirty(true)
    // })
  };

  const onClickBack = () => {
    dispatch(routerActions.push("/management/" + props.type));
  };

  const onClickReset = () => {
    setData(initialData);
  };

  return (
    <div className={className + " flex flex-col h-full overflow-hidden"}>
      <div className="md:flex md:items-center md:justify-between px-8 py-5 bg-white shadow-md z-10 border-b border-gray-100">
        <button
          type="button"
          className=" inline-flex p-2 mr-3 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          onClick={onClickBack}
        >
          <i className="fal fa-long-arrow-left" />
        </button>
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate">
            {id ? (
              <span>
                {data.last_name}, {data.first_name}
              </span>
            ) : (
              "Schüler*in hinzufügen"
            )}
          </h2>
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <ManagementSubmit
            create={data.id === null}
            isDirty={isDirty}
            data={data}
            post={_postData}
            delete={deleteData}
            onClickBack={onClickBack}
            onClickReset={onClickReset}
          />
        </div>
      </div>

      <div className="management-form flex-1 h-full overflow-auto bg-gray-100 p-12">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="grid grid-cols-3 gap-12 mb-8">
            <Input
              label="Vorname"
              value={data.first_name}
              onChange={(value) => setDataProp("first_name", value)}
            />
            <Input
              label="Name"
              value={data.last_name}
              onChange={(value) => setDataProp("last_name", value)}
            />
            <Input
              label="Rufname (optional)"
              value={data.call_name}
              required={false}
              onChange={(value) => setDataProp("call_name", value)}
            />
          </div>
          
          <div className="grid grid-cols-3 gap-12 mb-8">
            <SelectGender
              value={data.gender}
              onChange={(value) => setDataProp("gender", value)}
            />
            <InputDate
              label="Geburtstag"
              value={data.birthday}
              onChange={(value) => setDataProp("birthday", value)}
            />
            <Input
              label="Geburtsort (optional)"
              value={data.place_of_birth}
              required={false}
              onChange={(value) => setDataProp("place_of_birth", value)}
            />
          </div>

          <div className="grid grid-cols-3 gap-12 mb-8">
            <div className="col-span-2">
              <div className="grid grid-cols-3 gap-12">
                <Input
                  label="ID*"
                  value={data.external_id}
                  onChange={(value) => setDataProp("external_id", value)}
                />
                <SelectGroup
                  label="Klasse"
                  value={data.group_id}
                  onChange={(value) => setDataProp("group_id", value)}
                />
                <Input
                  label="Schulbesuchsjahr (optional)"
                  value={data.school_attendance_year}
                  required={false}
                  onChange={(value) => setDataProp("school_attendance_year", value)}
                />
              </div>
            </div>
            <Switch
              label="Versetzung"
              info="Ist in den nächsten Jahrgang versetzt"
              value={data.is_promotion}
              onChange={(value) => setDataProp("is_promotion", value)}
            />
          </div>

          <div className="mb-8">
            <MultiselectCourse
              value={data.course_ids}
              onChange={(value) => setDataProp("course_ids", value)}
            />
          </div>

          {isDirty && (
            <IsDirtyHelper
              route={props.route}
              original={original}
              data={data}
            />
          )}
        </form>
      </div>
    </div>
  );
};

export default connect((state, ownProps) => {
  const pupil = state.entities.pupils
    ? state.entities.pupils[ownProps.params.id]
    : null;
  return {
    type: "pupils",
    pupil,
    pupils: state.entities.pupils,
  };
})(PupilForm);
