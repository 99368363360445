import { schema } from 'normalizr'

export const courseSchema = new schema.Entity('courses')
export const courseCategorySchema = new schema.Entity('course_categories')
export const courseTextblockSchema = new schema.Entity('course_textblocks')
export const coursePupilSchema = new schema.Entity('course_pupils')
export const exportSectionSchema = new schema.Entity('export_sections')
export const documentTypeSchema = new schema.Entity('document_types')
export const exportSchema = new schema.Entity('exports')
// export const exportSettingsSchema = new schema.Entity('export_settings')
export const gradeSchema = new schema.Entity('grades')
export const groupSchema = new schema.Entity('groups')
export const pupilSchema = new schema.Entity('pupils')
export const ratingTypeSchema = new schema.Entity('rating_types')
export const reportSchema = new schema.Entity('reports')
export const settingSchema = new schema.Entity('settings')
export const teacherSchema = new schema.Entity('teachers')
export const textblockSchema = new schema.Entity('textblocks')
export const textblockCategorySchema = new schema.Entity('textblock_categories')
export const userSchema = new schema.Entity('user')
