import React from 'react'
import Textarea from '../form/Textarea'

const RatingText = (props) => {
  const onChangeText = (text) => {
    if (props.onChange) {
      props.onChange({
        ...props.report,
        text,
      })
    }
  }

  return <div>
    <label className="py-2 block text-sm font-medium text-gray-700">Freitext</label>
    <div className="p-2 bg-white shadow-lg border border-gray-200 rounded">
      <Textarea
        className="w-full mt-4 outline-none p-2 border-0 text-sm placeholder-gray-500 focus:outline-none focus:ring-0 rounded resize-none"
        placeholder="Sie können individuellen Text ergänzen…"
        onChange={onChangeText}
        value={props.report.text || ""}
        maxLength={props.ratingMaxLength}
      />
    </div>
  </div >
}

export default RatingText