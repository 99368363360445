import * as api from 'api'
import { receiveEntities } from 'actions/entities'
import { changeAuth, states } from 'actions/authentication'

export const requestUser = () => async (dispatch) => {
  const payload = await api.requestUser()
  dispatch(receiveEntities(payload))

  return dispatch(changeAuth({ state: states.AUTHENTICATED }))
}
