import { createSelector } from 'reselect'
import { get } from 'lodash'

const getSettings = state => get(state, 'entities.settings', null)

export const allSettingsSelector = createSelector(getSettings, (settingsList) => {
  if (!settingsList) {
    return {
      app_name: 'Zeugnisplattform',
      labels_code: 'Code',

      labels_group_separator: '-',
      labels_report: 'Report',
      labels_social_report: 'Sozial-Report',
      report_comment_maxlength: 500,
      report_show_absence_hours: true,
      school_name: '',
      school_prefix: 'demo',
      social_report_title: 'Sozial-Report',
      subject_report_maxlength: 500,
    }
  }
  const settings = settingsList[1]
  return {
    app_name: 'Zeugnisplattform',
    labels_code: 'Code',

    ...settings,

    labels_group_separator: settings.labels_group_separator ? settings.labels_group_separator : ' ',
  }
})

const settings = {
  allSettingsSelector,
}

export default settings
