import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

const Input = ({ label, value, placeholder, onChange, required, name, type, maxLength }) => {

  required = required !== false

  const [error, setError] = useState(false)
  const [touched, setTouched] = useState(false)

  const _onChange = (e) => {
    setTouched(true)
    onChange(e.target.value)
  }

  useEffect(() => {
    setError(!value || value === "")
  }, [value])

  let className = "shadow-sm block w-full border-gray-300 rounded-md"
  className += required && touched && error ? " border-red-300 focus:ring-red-500 focus:border-red-500" : " focus:ring-blue-500 focus:border-blue-500"

  return (
    <div>
      <label htmlFor={name || ""} className="py-2 block text-sm font-medium text-gray-700">{label}</label>
      <div>
      <input
        onChange={_onChange}
        value={value || ""}
        placeholder={placeholder || ""}
        className={className}
        type={type || "text"}
        name={name || ""}
        id={name || ""}
        maxLength={maxLength}
      />
      </div>
    </div>
  )
}

export default connect()(Input)