import * as realApi from "api";
// import fakeApi from './_fakeApi'
import { receiveEntities, removeEntity } from "actions/entities";

// const useFakeApi = process.env.REACT_APP_USE_MOCK_DATA // window && window.location && window.location.hostname === 'localhost'

const api = realApi; // useFakeApi ? fakeApi : realApi

export const requestTextblockCategories = () => async (dispatch) => {
  const payload = await api.requestTextblockCategories();
  // console.log({ payload })
  return dispatch(receiveEntities(payload));
};

export const requestTextblockCategory = (id) => async (dispatch) => {
  const payload = await api.requestTextblockCategory(id);
  // console.log({ payload })
  return dispatch(receiveEntities(payload));
};

export const postTextblockCategory = (data) => async (dispatch) => {
  const payload = await api.postTextblockCategory(data);
  // console.log({ payload })
  await dispatch(receiveEntities(payload));
  return payload;
};

export const deleteTextblockCategory = (data) => async (dispatch) => {
  return api
    .deleteTextblockCategory(data)
    .then(() =>
      dispatch(removeEntity({ entityType: "textblock_categories", ...data }))
    );
};
