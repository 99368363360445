import * as realApi from "api";
// import fakeApi from './_fakeApi'
import {
  clearEntities,
  receiveEntities,
  removeEntity,
  removeEntities,
} from "actions/entities";

// const useFakeApi = process.env.REACT_APP_USE_MOCK_DATA // window && window.location && window.location.hostname === 'localhost'

const api = realApi; // useFakeApi ? fakeApi : realApi

export const requestCourses = () => async (dispatch) => {
  const payload = await api.requestCourses();
  // console.log({ payload })
  return dispatch(receiveEntities(payload));
};

export const requestCourse = (id) => async (dispatch) => {
  const payload = await api.requestCourse(id);
  // console.log({ payload })
  return dispatch(receiveEntities(payload));
};

export const postCourse = (course) => async (dispatch) => {
  const payload = await api.postCourse(course);
  // console.log({ payload })
  return dispatch(receiveEntities(payload));
};

export const deleteCourse = (course) => async (dispatch) => {
  return api
    .deleteCourse(course)
    .then(() => dispatch(removeEntity({ entityType: "courses", ...course })));
};

export const requestCourseCategories = () => async (dispatch) => {
  const payload = await api.requestCourseCategories();
  // console.log({ payload })
  return dispatch(receiveEntities(payload));
};

export const requestCourseCategory = (id) => async (dispatch) => {
  const payload = await api.requestCourseCategory(id);
  // console.log({ payload })
  return dispatch(receiveEntities(payload));
};

export const postCourseCategory = (courseCategory) => async (dispatch) => {
  const payload = await api.postCourseCategory(courseCategory);
  // console.log({ payload })
  return dispatch(receiveEntities(payload));
};

export const deleteCourseCategory = (courseCategory) => async (dispatch) => {
  return api
    .deleteCourseCategory(courseCategory)
    .then(() =>
      dispatch(
        removeEntity({ entityType: "course_categories", ...courseCategory })
      )
    );
};

export const requestExportSections = () => async (dispatch) => {
  const payload = await api.requestExportSections();
  // console.log({ payload })
  return dispatch(receiveEntities(payload));
};

export const requestRatingTypes = () => async (dispatch) => {
  const payload = await api.requestRatingTypes();
  // console.log({ payload })
  return dispatch(receiveEntities(payload));
};

export const requestCoursePupils = (id) => async (dispatch) => {
  dispatch(removeEntities({ entityType: "course_pupils" }));
  const payload = await api.requestCoursePupils(id);
  // console.log({ payload })
  return dispatch(receiveEntities(payload));
};

export const requestCourseReports = (id) => async (dispatch) => {
  dispatch(removeEntities({ entityType: "course_reports" }));
  const payload = await api.requestCourseReports(id);
  // console.log({ payload })
  return dispatch(receiveEntities(payload));
};

export const postCourseReports = (courseId, reports) => async (dispatch) => {
  const payload = await api.postCourseReports(courseId, reports);
  // console.log({ payload })
  return dispatch(receiveEntities(payload));
};

export const requestCourseTextblocks = (id) => async (dispatch) => {
  dispatch(clearEntities({ entityType: "course_textblocks" }));
  const payload = await api.requestCourseTextblocks(id);
  // console.log({ payload })
  return dispatch(receiveEntities(payload));
};
