import { createAction } from 'redux-actions'

export const setScrollOffsetAction = createAction('SET_SCROLL_OFFSET')

export const setScrollOffset = (scrollOffset) => dispatch => dispatch(setScrollOffsetAction({ scrollOffset }))

export const setQueryAction = createAction('SET_QUERY')

export const setQuery = (query, context) => dispatch => dispatch(setQueryAction({ query, context }))

export const resetQueryAction = createAction('RESET_QUERY')

export const resetQuery = () => dispatch => dispatch(resetQueryAction({ query: '' }))

export const setSortAction = createAction('SET_SORT')

export const setSort = (sortBy, sortDirection) => dispatch => dispatch(setSortAction({ sortBy, sortDirection }))

export const resetSortAction = createAction('RESET_SORT')

export const resetSort = () => dispatch => dispatch(resetSortAction())
