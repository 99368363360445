import React from 'react'
import ManagementHeader from './ManagementHeader'
import ManagementTable from './ManagementTable'
import { requestPupils } from 'actions/pupils'
import i18n from 'i18n.json'

const PupilList = () => {
  const cols = {
    'external_id': {
      label: 'ID',
      sortable: true,
    },
    'last_name': {
      label: 'Name',
      sortable: true,
    },
    'first_name': {
      label: 'Vorname',
      sortable: true,
    },
    'call_name': {
      label: 'Rufname',
      sortable: true,
    },
    'gender': {
      label: 'Geschlecht',
      render: (value) => value ? i18n[`gender.${value}`] : value,
      sortable: true,
    },
    'group.name': {
      label: 'Klasse',
      sortable: true,
    },
    'birthday': {
      label: 'Geburtstag',
      render: (value) => value
        ? new Date(value).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' })
        : '',
      sortable: true,
    },
    'place_of_birth': {
      label: 'Geburtsort',
      sortable: true,
    },
    'is_promotion': {
      label: 'Wird versetzt',
      render: (value) => value === true ? 'Ja' : 'Nein',
      sortable: true,
    },
  }
  return (
    <div className="management managemant--pupils h-full overflow-hidden flex flex-col">
      <div className="flex-initial">
        <ManagementHeader type="pupils" title="Schüler*innen" />
      </div>
      <div className="flex-1 h-full">
        <ManagementTable type="pupils" requestItems={requestPupils} cols={cols} />
      </div>
    </div>
  )
}

export default PupilList