import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import InputMask from 'react-input-mask';

const InputInt = ({ label, value, placeholder, onChange, required, name, nullable }) => {

  required = required !== false
  nullable = nullable === true

  const [error, setError] = useState(false)
  const [touched, setTouched] = useState(false)

  const _onChange = (e) => {
    setTouched(true)
    const intValue = e.target.value ? parseInt(e.target.value) : null
    onChange(intValue)
  }

  useEffect(() => {
    if (nullable) {
      setError(false)
      return
    }
    setError(!value || value === "")
  }, [value, nullable])

  let className = "shadow-sm block w-full border-gray-300 rounded-md"
  className += required && touched && error ? " border-red-300 focus:ring-red-500 focus:border-red-500" : " focus:ring-blue-500 focus:border-blue-500"

  return (
    <div>
      <label htmlFor={name || ""} className="py-2 block text-sm font-medium text-gray-700">{label}</label>
      <div>
      <InputMask
        onChange={_onChange}
        value={![undefined, null].includes(value) ? value : null}
        placeholder={placeholder || ""}
        className={className}
        mask="9999999999"
        maskChar=""
        type="text"
        name={name || ""}
        id={name || ""}
      />
      </div>
    </div>
  )
}

export default connect()(InputInt)