import React from 'react'
import { withRouter } from 'react-router'
// import { isEqual } from 'lodash-es'

class IsDirtyHelper extends React.Component {

  componentDidMount() {
    // console.log(this.props)

    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave)
  }

  componentWillUnmount() {
    let remove = this.props.router.setRouteLeaveHook(this.props.route, (nextLocation) => {
      remove()
   })
  }

  routerWillLeave = () => {
    // if (isEqual(this.props.original, this.props.data)) {
    //   return true
    // }
    return 'Ungespeicherte Änderungen verwerfen?'
  }

  render() {
    return null
    // return <div>IsDirty</div>
  }
}

export default withRouter(IsDirtyHelper)