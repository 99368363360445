import React from 'react'
import { connect } from 'react-redux'
import { requestPupils } from 'actions/pupils'
import Multiselect from './Multiselect'

const MultiselectPupil = (props) => {
  return <Multiselect
    label="Schüler*innen"
    type="pupils"
    request={requestPupils}
    renderName={(pupil) => pupil.last_name + ', ' + pupil.first_name}
    groupBy="group"
    value={props.value}
    onChange={props.onChange}
  />
}

export default connect()(MultiselectPupil)