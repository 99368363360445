import * as realApi from "api";
// import fakeApi from './_fakeApi'
import { receiveEntities } from "actions/entities";

// const useFakeApi = process.env.REACT_APP_USE_MOCK_DATA // window && window.location && window.location.hostname === 'localhost'

const api = realApi; // useFakeApi ? fakeApi : realApi;

export const requestExports = () => async (dispatch) => {
  const payload = await api.requestExports();
  return dispatch(receiveEntities(payload));
};

export const postExports = (exports) => async (dispatch) => {
  const payload = await api.postExports(exports);
  return Promise.resolve(payload);
  // return dispatch(receiveEntities(payload))
};

export const requestDocumentTypes = () => async (dispatch) => {
  const payload = await api.requestDocumentTypes();
  return dispatch(receiveEntities(payload));
};
