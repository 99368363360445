import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import InputMask from 'react-input-mask';

const InputDate = ({ label, value, placeholder, onChange, required }) => {

  required = required !== false

  const [error, setError] = useState(false)
  const [touched, setTouched] = useState(false)

  const _onChange = (e) => {
    setTouched(true)
    const value = e.target.value.split('.').reverse().join('-')
    onChange(value)
  }

  useEffect(() => {
    setError(!value || !value.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/))
  }, [value])

  let className = "shadow-sm block w-full border-gray-300 rounded-md"
  className += required && touched && error ? " border-red-500" : ""

  return (
    <div className="input input--date">
      <label className="py-2 block text-sm font-medium text-gray-700">{label}</label>
      <InputMask
        onChange={_onChange}
        type="text"
        value={value ? value.split('-').reverse().join('.') : ""}
        placeholder={placeholder || ""}
        className={className}
        mask="99.99.9999"
      />
    </div>
  )
}

export default connect()(InputDate)