import React from 'react'
import { connect } from 'react-redux'
import ExportsForm from './ExportsForm'
import ExportsList from './ExportsList'

const Exports = (props) => {
  return <div className="flex-1 relative z-0 flex overflow-hidden">
    <aside className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
      {/* Start aside area*/}
      <ExportsList />
      {/* End aside area */}
    </aside>
    <main className="relative order-first flex flex-col flex-shrink-0 w-7/12 border-r border-gray-200">
      {/* Start secondary column (hidden on smaller screens) */}
      <ExportsForm />
      {/* End secondary column */}
    </main>
  </div>
}

export default connect()(Exports)