import React from 'react'
import Input from 'components/form/Input'

const RatingAbsenseHours = (props) => {
  const onChange = (key, value) => {
    if (props.onChange) {
      props.onChange({
        ...props.report,
        [key]: value,
      })
    }
  }
  const data = {
    absence_hours: "Fehlstunden",
    absence_hours_unexcused: "/ unentschuldigt",
    absence_days: "Fehltage",
    absence_days_unexcused: "/ unentschuldigt",
    absence_hours_delayed: "Verspätungen",
  }
  return <div className="">
    <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-10">
      {Object.keys(data).map(key =>
        <div key={key} className="sm:col-span-2">
          <Input label={data[key]} value={props.report[key]} onChange={(value) => onChange(key, value)} required={false} name={"" + props.report.id + '-' + key} type="text"/>
        </div>
      )}
    </div>
  </div>
}

export default RatingAbsenseHours