import { get, values } from 'lodash'
import { createSelector } from 'reselect'

export const getAuthState = state => state.authentication.state

const userSelector = state => get(state, 'entities.user', {})

const getActiveUser = createSelector(
  userSelector,
  users => values(users)[0],
)

const auth = {
  getAuthState,
  getActiveUser,
}

export default auth