import { handleActions } from 'redux-actions'
import { keys, values, set, get } from 'lodash'

import { receiveEntities, removeEntity, clearEntities, removeEntities } from 'actions/entities'

const reducer = handleActions({
  "LOAD_LOCALSTORAGE": (state, action) => {
    return { ...action.payload.entities }
  },
  [receiveEntities]: (state, action) => {
    const result = { ...state }
    keys(action.payload.entities).forEach((entityTypeKey) => {
      // console.log({entityTypeKey})
      const entityType = get(state, `['${entityTypeKey}']`, {})
      result[entityTypeKey] = { ...entityType }
      values(action.payload.entities[entityTypeKey]).forEach((entity) => {
        // todo: schema.idAttribute
        const key = entity.id
        // console.log({ entity })
        if (key) {
          // console.log("add", { entityTypeKey, key })
          set(result, `${entityTypeKey}.${key}`, { ...entity })
        }
      })
    })
    return result
  },
  [removeEntity]: (state, action) => {
    // todo: schema.idAttribute
    const key = action.payload.id
    // console.log("remove", { key })
    const { [key]: removed, ...rest } = state[action.payload.entityType]
    return {
      ...state,
      [action.payload.entityType]: { ...rest }
    }
  },
  [clearEntities]: (state, action) => {
    return {
      ...state,
      [action.payload.entityType]: {},
    }
  },
  [removeEntities]: (state, action) => {
    return {
      ...state,
      [action.payload.entityType]: null,
    }
  },
}, {})


export default reducer
