import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { isEqual } from 'lodash-es'
import { requestCourse, requestCourseCategories, requestCoursePupils, requestCourseReports, postCourseReports, requestCourseTextblocks } from 'actions/courses'
import { requestTextblockCategories } from 'actions/textblockCategories'
import RatingsCoursePupils from './RatingsCoursePupils'
import RatingsCourseReports from './RatingsCourseReports'
import IsDirtyHelper from 'components/management/IsDirtyHelper'
import { show } from 'react-notification-system-redux'

const postOptions = {
  title: 'Erfolgreich gespeichert!',
  message: 'Alle Änderungen wurden gespeichert.',
  position: 'tr',
  autoDismiss: 3,
}

const errorOptions = {
  title: 'Fehler aufgetreten!',
  message: 'Bitte versuchen Sie es erneut.',
  position: 'tr',
  autoDismiss: 3,
}

const RatingsCourse = (props) => {

  const [original, setOriginal] = useState()
  const [isDirty, setIsDirty] = useState(false)
  const [_reports, setReports] = useState()
  const [selectedPupil, setSelectedPupil] = useState()
  const [offset, setOffset] = useState(0)
  const [paddingBottom, setPaddingBottom] = useState(0)
  const [loading, setLoading] = useState(false)

  const refAside = useRef()
  const refMain = useRef()
  const refContent = useRef()

  const { params: { id }, reports, dispatch, courses } = props
  const coursesLength = courses ? Object.keys(courses).length : 0

  useEffect(() => {
    dispatch(requestTextblockCategories())
    dispatch(requestCourseCategories())
  }, [])

  useEffect(() => {
    refAside.current.scrollTo(0, 0)
    refMain.current.scrollTo(0, 0)
    
    if (id) {
      dispatch(requestCourseReports(id))
    }
    
    if (id) {
      dispatch(requestCoursePupils(id))
    }
    
    if (id) {
      dispatch(requestCourseTextblocks(id))
    }
  }, [id, dispatch])
  
  useEffect(() => {
    if (coursesLength > 0 && id) {
      dispatch(requestCourse(id))
    }
  }, [id, dispatch, coursesLength])

  useEffect(() => {
    setOriginal(reports ? [...reports] : null)
    setIsDirty(false)
    setReports(reports)

    if (reports && reports.length && reports.length > 0 && refMain && refContent) {
      let o = Math.max(
        refMain.current.offsetHeight,
        Math.ceil(refMain.current.offsetHeight - refContent.current.offsetHeight / reports.length - 39)
      )
      if (isNaN(o)) {
        o = 0
      }
      // console.log(refMain.current.offsetHeight, refContent.current.offsetHeight / reports.length)
      // console.log({ o })
      setPaddingBottom(o)
    }
  }, [reports])

  const onClickPupil = (pupil) => {
    setSelectedPupil(pupil)
  }

  const onChange = (reports) => {
    // console.log("RatingsCourse.onChange",JSON.stringify(original)===JSON.stringify(reports))
    // console.log("RatingsCourse.onChange",JSON.stringify(original[0])===JSON.stringify(reports[0]))
    if (!isEqual(original, reports)) {
      // console.log("onChange",JSON.stringify(original[0])===JSON.stringify(reports[0]))
      // console.log("onChange",JSON.stringify(original)===JSON.stringify(reports))
      // console.log("onChange",original[0],reports[0])
      // console.log("o",original[0])
      // console.log("r",reports[0])
      setReports(reports)
      // console.log("onChange", reports, isEqual(original, reports))
      setIsDirty(true)
    }
  }

  const onClickSaveReports = () => {
    if (loading) {
      return false
    }
    setLoading(true)
    props.dispatch(postCourseReports(id, _reports))
      .then(() => {
        setLoading(false)
        setOriginal(null)
        setIsDirty(false)
        props.dispatch(show(postOptions, 'success'))
      })
      .catch(() => {
        setLoading(false)
        props.dispatch(show(errorOptions, 'error'))
      })
  }

  const onScroll = (event) => {
    if (reports && pupils) {
      // const ratio = refMain.current.scrollTop / (refContent.current.offsetHeight - refMain.current.offsetHeight)
      const ratio = (refMain.current.scrollTop) / (refContent.current.offsetHeight - refContent.current.offsetHeight / reports.length - 39)
      // console.log(refMain.current.scrollTop + refContent.current.offsetHeight / reports.length, ratio)
      // console.log(refMain.current.scrollTop, refMain.current.offsetHeight, refContent.current.offsetHeight, paddingBottom)
      let len = pupils ? pupils.length - 1 : 0
      setOffset(Math.floor(len * ratio))
    }
  }

  const { course, pupils, textblocks, textblockCategories } = props

  return <div className="flex-1 relative z-0 flex overflow-hidden">
    <main ref={refMain} className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last" onScroll={onScroll} style={{ paddingBottom }}>
      <div className="absolute inset-0 py-6">
        <div className="h-full">
          <div ref={refContent} className="pb-28">
            {course && pupils && _reports && textblocks && textblockCategories &&
              <RatingsCourseReports
                course={course}
                pupils={pupils}
                reports={_reports}
                textblocks={textblocks}
                textblockCategories={textblockCategories}
                selectedPupil={selectedPupil}
                onChange={onChange}
              />
            }
            {(!course || !_reports || !textblocks) && <div className="p-16 m-2">
              <i className="fal fa-circle-notch fa-spin" />
            </div>}
            {(loading || (course && pupils && _reports && textblocks && isDirty)) &&
              <button
                onClick={onClickSaveReports}
                type="submit"
                className="px-8 py-4 bg-blue-600 hover:bg-blue-800 disabled:bg-blue-800 border border-transparent font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-white rounded-full fixed bottom-0 right-0 m-12 text-lg shadow-xl"
                disabled={loading}
              >
                {loading ? <i className="fas fa-fw fa-circle-notch fa-spin -ml-1 mr-3" /> : <i className="fas fa-fw fa-cloud-upload -ml-1 mr-3" />}
                Speichern
              </button>
            }
            {course && pupils && _reports && textblocks && isDirty &&
              <IsDirtyHelper route={props.route} />
            }
          </div>
        </div>
      </div>
    </main>
    <aside ref={refAside} className="hidden relative lg:order-first lg:flex lg:flex-col flex-shrink-0 w-60 border-r border-gray-200 bg-gray-100 overflow-y-scroll">
      <div className="absolute inset-0 py-6 px-4">
        <div className="h-full">
          {course && pupils && // _reports && textblocks &&
            <RatingsCoursePupils
              pupils={pupils}
              reports={_reports}
              onClickPupil={onClickPupil}
              activeOffset={offset}
            />
          }
          {(!course || !pupils) && <div className="p-16 m-2">
            <i className="fal fa-circle-notch fa-spin" />
          </div>}
        </div>
      </div>
    </aside>
  </div>
}

export default connect((state, ownProps) => {
  // let courseCategories = state.entities.course_categories || {}
  let courses = state.entities.courses || null
  
  // courses = Object.keys(courses)
  //   .reduce((acc, key) => {
  //     const _c = courses[key]
  //     console.log({key},_c.child_courses)
  //     // // console.log(courses[key])
  //     if (_c.child_courses) {
  //       _c.child_courses.map(c => {
  //         // console.log(c)
  //         acc[c.id] = { ...c, course_category: courseCategories[c.course_category_id], child_courses: [] }
  //       })
  //     }
  //     //  else {
  //     //   acc[_c.id] = { ..._c, course_category: courseCategories[_c.course_category_id], child_courses: [] }
  //     // }
  //     return acc
  //   }, courses)

  const course = courses && courses[ownProps.params.id]
    ? courses[ownProps.params.id]
    : null

  const pupils = course && state.entities.course_pupils
    ? course.pupil_ids.reduce((acc, id) => {
      if (state.entities.course_pupils[id]) {
        acc.push(state.entities.course_pupils[id])
      }
      return acc
    }, [])
      .sort((a, b) => {
        return a.last_name > b.last_name ? 1 : -1
      })
    : null

  const textblocks = course && state.entities.course_textblocks
    ? Object.keys(state.entities.course_textblocks)
      .map(key => state.entities.course_textblocks[key])
      .filter(t => {
        // console.log(t.course_category_id)
        return t.course_category_id === course.course_category_id
      })
    : []

  const textblockCategories = state.entities.textblock_categories
    ? Object.keys(state.entities.textblock_categories)
      .map(key => state.entities.textblock_categories[key])
    : []

  const reports = pupils && state.entities.reports
    ? Object.keys(state.entities.reports)
      .filter(reportId => {
        const report = state.entities.reports[reportId]
        return report.course_id === course.id
      })
      .map(key => state.entities.reports[key])
      .sort((a, b) => {
        if (!pupils) {
          return 0
        }
        const pa = pupils.find(p => p.id === a.pupil_id)
        const pb = pupils.find(p => p.id === b.pupil_id)
        if (!pa || !pb) {
          return 0
        }
        // console.log({a,b,pupils})
        return pa.last_name > pb.last_name ? 1 : -1
      })
    : null

  return {
    courses,
    course,
    pupils,
    textblocks,
    textblockCategories,
    reports,
  }
})(RatingsCourse)