import React, { useState } from "react";
import { connect } from "react-redux";
import Logo from "./Logo";
import { login } from "actions/authentication";

const LoginPage = ({ dispatch }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  const onSubmit = (event) => {
    event.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    setError(false);
    dispatch(login(username, password))
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
    return false;
  };
  return (
    <div className="min-h-screen bg-blue-700 flex flex-col justify-center pt-2 pb-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="w-10 h-10 mx-auto">
          <Logo />
        </div>
        <h2 className="mt-2 text-center text-2xl font-extrabold text-white">
          ZeitFürSchule
        </h2>
        <p className="mt-0 text-center text-sm text-blue-200">
          Bitte melden Sie sich an, um fortzufahren.
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={(event) => onSubmit(event)}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                E-Mail Adresse
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="text"
                  // autoComplete="email"
                  required
                  placeholder=""
                  onChange={(e) => setUsername(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Passwort
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            {error && (
              <div className="text-red-500 w-full pt-2 text-center font-medium text-sm">
                <i className="fas fa-exclamation-circle text-red-500" />{" "}
                Benutzer oder Passwort falsch.
              </div>
            )}

            <div>
              <button
                type="submit"
                disabled={!username || !password || loading}
                className="w-full mt-2 justify-center py-2 px-8 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-blue-400 disabled:text-blue-100"
              >
                {loading ? (
                  <i className="fas fa-fw fa-circle-notch fa-spin -ml-1 mr-3" />
                ) : (
                  <i className="fas fa-fw fa-sign-in -ml-1 mr-3" />
                )}
                Anmelden
              </button>
            </div>

            <div className="flex items-center justify-between">
              <div className="text-sm">
                <a
                  href="mailto:support@zeitfuerschule.de"
                  className="font-medium text-blue-600 hover:text-blue-500"
                >
                  Hilfe benötigt?
                </a>
              </div>
              <div className="text-sm">
                <a
                  href={`${window.location.origin.replace(
                    ".",
                    "-api."
                  )}/account/password_reset/`}
                  className="font-medium text-blue-600 hover:text-blue-500"
                >
                  Passwort vergessen?
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect()(LoginPage);
