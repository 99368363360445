import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SelectCourseCategory from "../form/SelectCourseCategory";
import SelectRatingType from "../form/SelectRatingType";
import InputInt from "components/form/InputInt";

const ChildCourseForm = (props) => {
  const { course, label, placeholderLength } = props;

  const [_course, setCourse] = useState(course || {});

  const onChange = (key, value) => {
    const c = {
      ..._course,
      [key]: value,
    };
    setCourse(c);
    if (props.onChange) {
      props.onChange(c);
    }
  };

  const onAdd = () => {
    if (props.onAdd) {
      props.onAdd(_course);
      setCourse({});
    }
    props.toggleVisibility();
  };

  useEffect(() => {
    if (course) {
      setCourse(course);
    }
  }, [course]);

  return (
    <div className={`management-form flex-1 ${props.onAdd ? 'bg-gray-200 rounded-2xl' : ''}`}>
      {/* {props.onAdd && (
        <div className="mt-6">
          <div className="w-full items-center font-bold">
            Beurteilungsbereich hinzufügen
          </div>
        </div>
      )}*/}
      <div className={`flex justify-center items-end space-x-4 ${props.onAdd ? 'p-8 pt-4' : ''}`}>
        {/* <div className="flex-1">
          <p>{label}</p>
        </div> */}
        <div className="flex-1">
          <SelectRatingType
            label={label}
            value={
              _course.rating_type
                ? _course.rating_type.id
                : _course.rating_type_id
            }
            onChange={(value) => onChange("rating_type_id", value)}
          />
        </div>
        <div className="flex-1">
          <SelectCourseCategory
            label="Kurskategorie / Fachbereich *"
            value={_course.course_category_id}
            onChange={(value) => onChange("course_category_id", value)}
          />
        </div>
        <div className="flex-1">
          <InputInt
            label="Maximale Zeichenlänge (optional)"
            placeholder={props.placeholderLength}
            nullable={true}
            value={_course.rating_max_length}
            onChange={(value) => onChange("rating_max_length", value)}
          />
        </div>
        <div className="flex-initial">
          {props.onDelete && (
            <button
              type="button"
              onClick={props.onDelete}
              className="inline-flex items-center rounded-md border border-gray-300 px-4 py-2 text-base font-medium text-blue-700 hover:bg-gray-200 hover:text-blue-800"
            >
              Löschen <i className="pl-3 far fa-trash" />
            </button>
          )}
          {props.onAdd && (
            <button
              type="button"
              disabled={!_course.rating_type_id || !_course.course_category_id}
              onClick={onAdd}
              className="inline-flex items-center rounded-md border border-gray-300 px-4 py-2 text-base font-medium text-blue-700 hover:bg-gray-200 hover:text-blue-800 disabled:text-gray-400"
            >
              Speichern <i className="pl-3 far fa-plus" />
            </button>
          )}
          {props.onAdd && (
            <button
              onClick={props.toggleVisibility}
              type="button"
              className="ml-2 inline-flex items-center rounded-md border border-gray-300 mt-6 px-4 py-2 text-base font-medium text-blue-700 hover:bg-gray-200 hover:text-blue-800"
            >
              Abbrechen
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect((state) => {
  return {
    ratingTypes: state.entities.rating_types,
    courseCategories: state.entities.course_categories,
  };
})(ChildCourseForm);
