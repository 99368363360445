import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { show } from 'react-notification-system-redux'

const postOptions = {
  title: 'Erfolgreich gespeichert!',
  message: 'Die Änderungen wurden gespeichert.',
  position: 'tr',
  autoDismiss: 3,
}

const deleteOptions = {
  title: 'Erfolgreich gelöscht!',
  message: 'Der gewählte Eintrag wurde gelöscht.',
  position: 'tr',
  autoDismiss: 3,
}

const errorOptions = {
  title: 'Fehler aufgetreten!',
  message: 'Bitte versuchen Sie es erneut.',
  position: 'tr',
  autoDismiss: 3,
}

const ManagementSubmit = (props) => {

  const { data, isDirty } = props

  const [isValid, setIsValid] = useState(false)
  const [loadingContext, setLoadingContext] = useState(null)

  const onClickSaveAndBack = () => {
    setLoadingContext('SAVE_AND_BACK')
    props.dispatch(props.post(data))
      .then(() => {
        setLoadingContext(null)
        props.dispatch(show(postOptions, 'success'))
      })
      .then(() => {
        if (props.onClickBack) {
          props.onClickBack()
        }
      })
      .catch(() => {
        setLoadingContext(null)
        props.dispatch(show(errorOptions, 'error'))
      })
  }

  const onClickSaveAndReset = () => {
    setLoadingContext('SAVE_AND_RESET')
    props.dispatch(props.post(data))
      .then(() => {
        setLoadingContext(null)
        props.dispatch(show(postOptions, 'success'))
      })
      .then(() => {
        if (props.onClickReset) {
          props.onClickReset()
        }
      })
      .catch(() => {
        setLoadingContext(null)
        props.dispatch(show(errorOptions, 'error'))
      })
  }

  const onClickDelete = () => {
    if (window.confirm("Sind Sie sicher? Es werden unter Umständen auch hiermit verbundene Daten gelöscht. DIES KANN NICHT RÜCKGÄNGIG GEMACHT WERDEN!")) {
      setLoadingContext('DELETE')
      props.dispatch(props.delete(data))
        .then(() => {
          setLoadingContext(null)
          props.dispatch(show(deleteOptions, 'success'))
        })
        .then(props.onClickBack)
        .catch(() => {
          setLoadingContext(null)
          props.dispatch(show(errorOptions, 'error'))
        })
    }
  }

  useEffect(() => {
    const validateProps = (data, valid = true) => {
      return Object.keys(data).reduce((acc, key) => {
        const value = data[key]
        if (key === 'id') {
          return acc
        }
        // quickfix -> rating_max_length is optional/nullable
        if (key === 'rating_max_length') {
          return acc
        }
        if (key === 'place_of_birth') {
          return acc
        }
        if (key === 'school_attendance_year') {
          return acc
        }
        if (key === 'call_name') {
          return acc
        }
        if (value === null) {
          return false
        }
        if (typeof value === 'string' && value === '') {
          return false
        }
        if (value && value.constructor === Object) {
          return validateProps(value, acc)
        }
        return acc
      }, valid)
    }

    if (!data) {
      setIsValid(false)
    } else {
      setIsValid(validateProps(data))
    }
  }, [data])

  return <div>
    <div>
      {!props.create &&
        <span>
          {props.delete &&
            <button
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 text-red-500"
              onClick={onClickDelete}
              disabled={loadingContext !== null}
            >
              {loadingContext !== 'DELETE' && <span>Löschen</span> }
              {loadingContext === 'DELETE' && <span><i className="fa-fw fal fa-circle-notch fa-spin" /> Löschen</span>}
            </button>
          }
        </span>
      }
      {/* <button
          type="button"
          className="ml-3 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          onClick={props.onClickBack}
        >
          Abbrechen
        </button> */}
      <span>
      {props.create &&
        <span>
          {props.post &&
            <button
            className="ml-3 inline-flex items-center px-4 py-2 border disabled:text-gray-400 border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              onClick={onClickSaveAndReset}
              disabled={!isValid || loadingContext !== null}
            >
              {loadingContext !== 'SAVE_AND_RESET' && <span>Speichern &amp; Weiteren</span>}
              {loadingContext === 'SAVE_AND_RESET' && <span><i className="fal fa-fw fa-circle-notch fa-spin" /> Speichern &amp; Weiteren</span>}
            </button>
          }
        </span>
      }
        {props.post &&
          <button
          className="ml-3 inline-flex items-center px-4 py-2 disabled:text-blue-100 disabled:bg-blue-400 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={onClickSaveAndBack}
            disabled={!isDirty || !isValid || loadingContext !== null}
          >
            {loadingContext !== 'SAVE_AND_BACK' && <span>Speichern</span>}
            {loadingContext === 'SAVE_AND_BACK' && <span><i className="fal fa-fw fa-circle-notch fa-spin" /> Speichern</span>}
          </button>
        }
      </span>
      
    </div>
    {/* <div className="mb-8">
      <pre className="h-48 mt-8">
        {JSON.stringify(data, null, 2)}
      </pre>
    </div> */}
  </div>
}

export default connect()(ManagementSubmit)