import React from 'react'
import ManagementHeader from './ManagementHeader'
import ManagementTable from './ManagementTable'
import { requestGroups } from 'actions/groups'

const GroupList = () => {
  const cols = {
    'grade.name': {
      label: 'Jahrgang',
      sortable: true,
    },
    'name_short': {
      label: 'Name',
      sortable: true,
    },
    'name': {
      label: 'Anzeigename',
      sortable: true,
    },
    'pupil_ids': {
      label: 'Anzahl Schüler*innen',
      render: (value) => value ? value.length : 0,
      sortable: true,
    },
  }
  return (
    <div className="management managemant--groups h-full overflow-hidden flex flex-col">
      <div className="flex-initial">
        <ManagementHeader type="groups" title="Klassen" />
      </div>
      <div className="flex-1 h-full">
        <ManagementTable type="groups" requestItems={requestGroups} cols={cols} />
      </div>
    </div>
  )
}

export default GroupList