import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { requestGroups } from 'actions/groups'
import SelectWrapper from './SelectWrapper'

const SelectGroup = (props) => {
  const { groups, dispatch } = props
  const [init, setInit] = useState(false)

  useEffect(() => {
    if (!init && (!groups || groups.length === 0)) {
      setInit(true)
      dispatch(requestGroups())
    }
  }, [groups, dispatch, init, setInit])

  return (
    <div className="select select--group">
      <label className="py-2 block text-sm font-medium text-gray-700">Klasse</label>
      <SelectWrapper>
        <select
          onChange={(e) => props.onChange(parseInt(e.target.value))}
          value={props.value || ""}
          className="shadow-sm block w-full border-gray-300 rounded-md outline-none border appearance-none"
        >
          <option value="" disabled>Auswählen...</option>
          {props.groups.sort((t1, t2) => {
            return t1.name > t2.name ? 1 : -1
          }).map(group => {
            return group
              ? <option
                key={group.id}
                value={group.id}
              >
                {group.name}
              </option>
              : null
          })}
        </select>
      </SelectWrapper>
    </div>
  )
}

export default connect((state, ownProps) => {
  const groups = state.entities && state.entities.groups
    ? Object.keys(state.entities.groups).map((key) => {
      return state.entities.groups[key]
    })
    : []
  return {
    groups,
  }
})(SelectGroup)