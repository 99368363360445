import * as realApi from "api";
// import fakeApi from './_fakeApi'
import { receiveEntities, removeEntity } from "actions/entities";

// const useFakeApi = process.env.REACT_APP_USE_MOCK_DATA; // window && window.location && window.location.hostname === 'localhost'

const api = realApi; // useFakeApi ? fakeApi : realApi;

export const requestGroups = () => async (dispatch) => {
  const payload = await api.requestGroups();
  // console.log({ payload })
  return dispatch(receiveEntities(payload));
};

export const requestGroup = (id) => async (dispatch) => {
  const payload = await api.requestGroup(id);
  // console.log({ payload })
  return dispatch(receiveEntities(payload));
};

export const postGroup = (data) => async (dispatch) => {
  const payload = await api.postGroup(data);
  // console.log({ payload })
  return dispatch(receiveEntities(payload));
};

export const deleteGroup = (group) => async (dispatch) => {
  return api
    .deleteGroup(group)
    .then(() => dispatch(removeEntity({ entityType: "groups", ...group })));
};
