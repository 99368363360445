import React from "react";
import ManagementHeader from "./ManagementHeader";
import ManagementTable from "./ManagementTable";
import { requestTeachers } from "actions/teachers";
import i18n from "i18n.json";

const TeacherList = () => {
  const cols = {
    code: {
      label: "Kürzel",
      sortable: true,
    },
    gender: {
      label: "Anrede",
      render: (value) => (value ? i18n[`gender.${value}`] : value),
      sortable: true,
    },
    "user.first_name": {
      label: "Vorname",
      sortable: true,
    },
    "user.last_name": {
      label: "Name",
      sortable: true,
    },
    "user.email": {
      label: "E-Mail",
      sortable: true,
    },
    course_ids: {
      label: "Kurse",
      render: (value) => (value ? value.length : 0),
      sortable: true,
    },
    "user.is_active": {
      label: "Aktiv",
      render: (value) => (value === true ? "Ja" : "Nein"),
      sortable: true,
    },
    "user.is_staff": {
      label: "Verwaltungsmitglied",
      render: (value) => (value === true ? "Ja" : "Nein"),
      sortable: true,
    },
    "user.last_login": {
      label: "Letzter Login",
      render: (value) => {
        const options = {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        };
        return value
          ? new Date(value).toLocaleDateString("de-DE", options) + " Uhr"
          : "";
      },
      sortable: true,
    },
  };
  return (
    <div className="management managemant--teachers h-full overflow-hidden flex flex-col">
      <div className="flex-initial">
        <ManagementHeader type="teachers" title="Lehrkräfte" />
      </div>
      <div className="flex-1 h-full">
        <ManagementTable
          type="teachers"
          requestItems={requestTeachers}
          cols={cols}
        />
      </div>
    </div>
  );
};

export default TeacherList;
