import React from 'react'
import ManagementHeader from './ManagementHeader'
import ManagementTable from './ManagementTable'
import { requestTextblockCategories } from 'actions/textblockCategories'

const TextblockCategoryList = () => {
  const cols = {
    'id': {
      label: 'ID',
      sortable: true,
    },
    // 'type': {
    //   label: 'Typ',
    // },
    'name': {
      label: 'Kompetenz',
      sortable: true,
    },
    'export_order': {
      label: 'Reihenfolge',
      sortable: true,
    },
  }
  return (
    <div className="management managemant--textblock_categories h-full overflow-hidden flex flex-col">
      <div className="flex-initial">
        <ManagementHeader type="textblock_categories" title="Beurteilungskategorien" />
      </div>
      <div className="flex-1 h-full">
        <ManagementTable type="textblock_categories" requestItems={requestTextblockCategories} cols={cols} />
      </div>
    </div>
  )
}

export default TextblockCategoryList