import { hashHistory } from 'react-router'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { routerMiddleware } from 'react-router-redux'

import RavenIntegration from 'integrations/Raven'

import reducer from 'reducers'

const enhancers = [
  applyMiddleware(
    thunk,
    routerMiddleware(hashHistory),
    ...RavenIntegration.getMiddlewares(),
  ),
]

if (window.devToolsExtension && process.env.NODE_ENV !== 'production') {
  enhancers.push(window.devToolsExtension())
}

const store = createStore(
  reducer, compose(...enhancers),
)

export default store
