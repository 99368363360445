import React from 'react'
import { connect } from 'react-redux'
import { requestTeachers } from 'actions/teachers'
import Multiselect from './Multiselect'

const MultiselectTeacher = (props) => {
  return <Multiselect
    label="Lehrkräfte"
    type="teachers"
    request={requestTeachers}
    renderName={(teacher) => teacher.user.last_name + ', ' + teacher.user.first_name}
    value={props.value}
    onChange={props.onChange}
  />
}

export default connect()(MultiselectTeacher)