import React from 'react'
import { connect } from 'react-redux'
import Logo from './Logo'

class Header extends React.Component {
  render() {
    return (
      <div className="text-white border-white border-b border-opacity-25 w-full">
        <div className="w-full flex flex-row justify-center items-center">
          <div className="flex-initial">
            <div className="w-8 h-6">
              <Logo />
            </div>
          </div>
          <div className="flex-1 flex flex-col p-3">
            <div className="font-semibold text-sm">
              ZeitFürSchule
            </div>
            <div className="text-xs">
              Zeugnisplattform
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect()(Header)