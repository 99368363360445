import React from 'react'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'
// import Sidebar from 'components/Sidebar'
import Layout from 'containers/Layout'
import AuthSelector from 'selectors/auth'

const Management = (props) => {
  const { children, user, dispatch } = props
  if (!user || !user.is_staff) {
    dispatch(routerActions.push('/ratings'))
  }
  const navigation = [
    {
      headline: '',
      items: [
        {
          name: 'Zurück',
          icon: 'long-arrow-left',
          href: '#/ratings',
        },
      ]
    },
    {
      headline: '',
      items: [
        {
          name: 'Klassen',
          icon: 'users',
          href: '#/management/groups',
        },
        {
          name: 'Lehrkräfte',
          icon: 'graduation-cap',
          href: '#/management/teachers',
        },
        {
          name: 'Schüler*innen',
          icon: 'user',
          href: '#/management/pupils',
        },
        {
          name: 'Kurskategorien',
          icon: 'atom-alt',
          href: '#/management/course_categories',
        },
        {
          name: 'Kurse',
          icon: 'bookmark',
          href: '#/management/courses',
        },
        {
          name: 'Beurteilungskategorien',
          icon: 'list-ul',
          href: '#/management/textblock_categories',
        },
        {
          name: 'Beurteilungstexte',
          icon: 'font-case',
          href: '#/management/textblocks',
        },
        {
          name: 'Export',
          icon: 'cloud-download',
          href: '#/management/exports',
        },
      ]
    }
  ].map(item => {
    return {
      ...item,
      current: props.router.getCurrentLocation().pathname.indexOf(item.href) === 0,
    }
  })

  return <Layout navigation={navigation}>
    {children}
    {children === null &&
      <div className="w-full h-full flex flex-col justify-center items-center">
        <i className="fal fa-mug-hot fa-4x text-gray-300 p-4" />
        <span className="text-gray-500">Bitte treffen Sie eine Auswahl in der Navigation links!</span>
      </div>
    }
  </Layout>

  // return (
  //   <div className="w-full h-full flex overflow-hidden">
  //     <nav className="w-1/6 min-w-min min-w-min pr-4 pb-8 overflow-y-auto bg-blue-700">
  //       <Sidebar {...props}>
  //         <ul className="text-white hover:text-white">
  //           <li>
  //             <button
  //               className="mb-1 text-white no-underline flex w-full items-center focus:outline-none p-3 pl-6 hover:no-underline hover:text-white hover:bg-gray-100 hover:bg-opacity-25 focus:no-underline rounded-r-lg cursor-pointer"
  //               onClick={() => dispatch(routerActions.push('/ratings'))}
  //             >
  //               <i className="text-2xl w-10 mr-2 fal fa-long-arrow-left"></i>
  //               Zurück
  //             </button>
  //           </li>
  //         </ul>
  //         <ul className="text-white hover:text-white">
  //           {Object.keys(nav).map(key => {
  //             const href = '/management/' + key
  //             const className = props.router.getCurrentLocation().pathname.indexOf(href) === 0 ? 'bg-gray-100 bg-opacity-25' : ''
  //             return <li key={key}>
  //               <button
  //                 className={`${className} mb-1 text-white no-underline flex w-full items-center focus:outline-none p-3 pl-6 hover:no-underline hover:text-white hover:bg-gray-100 hover:bg-opacity-25 focus:no-underline rounded-r-lg cursor-pointer`}
  //                 onClick={() => dispatch(routerActions.push(href))}
  //               >
  //                 {nav[key].icon && <i className={'text-2xl w-10 mr-2 fal fa-' + nav[key].icon} />}
  //                 {nav[key].name}
  //               </button>
  //             </li>
  //           })}
  //         </ul>
  //         {/* </div> */}
  //       </Sidebar>
  //     </nav >
  //     <div className="w-5/6 flex bg-white">
  //       <div className="w-full bg-white overflow-y-scroll">
  //         {children}
  //         {children === null &&
  //           <div className="w-full h-full flex flex-col justify-center items-center">
  //             <i className="fal fa-mug-hot fa-4x text-gray-300 p-4" />
  //             <span className="text-gray-500">Bitte treffen Sie eine Auswahl in der Navigation links!</span>
  //           </div>
  //         }
  //       </div>
  //     </div>
  //   </div >
  // )
}

export default connect(state => ({ user: AuthSelector.getActiveUser(state) }))(Management)
