import React from 'react'
import ManagementHeader from './ManagementHeader'
import ManagementTable from './ManagementTable'
import { requestTextblocks } from 'actions/textblocks'

const TextblockList = () => {
  const cols = {
    'id': {
      label: 'ID',
      sortable: true,
    },
    'textblock_category': {
      label: 'Kategorie',
      sortable: true,
      render: (value) => value ? value.name : '-',
    },
    'text': {
      label: 'Text',
      sortable: true,
    },
    'rating': {
      label: 'Bewertung',
      sortable: true,
    },
    // 'grades': {
    //   label: 'Jahrgänge',
    //   render: (value) => value && value.length > 0 ? value.map(v => v.name).join(', ') : '-',
    // },
    'grades': {
      label: 'Jahrgänge',
      render: (value) => value && value.length > 0 ? value.map(v => v.name).join(', ') : '-',
    },
    'course_categories': {
      label: 'Kurskategorien',
      render: (value) => value && value.length > 0 ? value.map(v => '' + v.name + ' (' + v.name_short + ')' ).join(', ') : '-',
    },
  }
  return (
    <div className="management managemant--textblocks h-full overflow-hidden flex flex-col">
      <div className="flex-initial">
        <ManagementHeader type="textblocks" title="Beurteilungstexte" />
      </div>
      <div className="flex-1 h-full">
        <ManagementTable type="textblocks" requestItems={requestTextblocks} cols={cols} />
      </div>
    </div>
  )
}

export default TextblockList