import React from 'react'
import { connect } from 'react-redux'
import { allSettingsSelector } from 'selectors/settings'
import AuthSelector from 'selectors/auth'
import { logout } from 'actions/authentication'
import { requestUser } from 'actions/user'
import { requestSettings } from 'actions/settings'

class User extends React.Component {

  componentDidMount() {
    if (!this.props.user) {
      this.props.dispatch(requestUser())
      this.props.dispatch(requestSettings())
    }
  }

  render() {
    const { user, config } = this.props
    return <div className="flex-shrink-0 w-full group block">
      <div className="flex items-center">
        <div className="h-9 w-9 rounded-full bg-white bg-opacity-25 flex justify-center items-center">
          <i className="fal fa-user text-sm" />
        </div>
        <div className="ml-3">
          {user && <span className="text-sm font-medium">{user.first_name} {user.last_name}</span>}
          {config && <p className="block text-xs m-0">{config.school_name}</p>}
          {/* {user.is_staff && <a className="blocktext-xs" href="#/management/"><i className="fal fa-long-arrow-right" /> Verwaltungsbereich</a>} */}
          <button
            className="block text-blue-200 hover:text-white text-xs cursor-pointer focus:outline-none"
            onClick={() => this.props.dispatch(logout())}
          >
            Abmelden
          </button>
        </div>
      </div>
    </div>
  }
}

export default connect((state) => ({
  user: AuthSelector.getActiveUser(state),
  config: allSettingsSelector(state),
}))(User)