import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { requestCourseCategories } from 'actions/courses'
import SelectWrapper from './SelectWrapper'

const SelectCourseCategory = (props) => {
  const { courseCategories, dispatch, label } = props
  const [init, setInit] = useState(false)

  useEffect(() => {
    if (!init && (!courseCategories || courseCategories.length === 0)) {
      setInit(true)
      dispatch(requestCourseCategories())
    }
  }, [courseCategories, dispatch, init, setInit])

  return (
    <div className="select select--grade">
      <label className="py-2 block text-sm font-medium text-gray-700">{label || "Kurskategorie"}</label>
      <SelectWrapper>
        <select
          onChange={(e) => props.onChange(parseInt(e.target.value))}
          value={props.value || ""}
          className="shadow-sm block w-full border-gray-300 rounded-md outline-none border appearance-none"
        >
          <option value="" disabled>Auswählen...</option>
          {props.courseCategories.sort((t1, t2) => {
            return t1.export_order > t2.export_order ? 1 : -1
          }).map(category => {
            return <option
              key={category.id}
              value={category.id}
            >
              {category.name} ({category.name_short})
            </option>
          })}
        </select>
      </SelectWrapper>
    </div>
  )
}

export default connect((state, ownProps) => {
  const courseCategories = state.entities && state.entities.course_categories
    ? Object.keys(state.entities.course_categories).map((key) => {
      return state.entities.course_categories[key]
    })
    : []
  return {
    courseCategories,
  }
})(SelectCourseCategory)