import * as realApi from "api";
// import fakeApi from './_fakeApi'
import { receiveEntities, removeEntity } from "actions/entities";

// const useFakeApi = process.env.REACT_APP_USE_MOCK_DATA // window && window.location && window.location.hostname === 'localhost'

const api = realApi; // useFakeApi ? fakeApi : realApi

export const requestPupils = () => async (dispatch) => {
  const payload = await api.requestPupils();
  // console.log({ payload })
  return dispatch(receiveEntities(payload));
};

export const requestPupilsByGroup = (groupId) => async (dispatch) => {
  const payload = await api.requestPupilsByGroup(groupId);
  // console.log({ payload })
  return dispatch(receiveEntities(payload));
};

export const requestPupil = (pupilId) => async (dispatch) => {
  const payload = await api.requestPupil(pupilId);
  // console.log({ payload })
  return dispatch(receiveEntities(payload));
};

export const postPupil = (data) => async (dispatch) => {
  const payload = await api.postPupil(data);
  // console.log({ payload })
  await dispatch(receiveEntities(payload));
  return payload;
};

export const deletePupil = (data) => (dispatch) =>
  api
    .deletePupil(data)
    .then(() => dispatch(removeEntity({ entityType: "pupils", ...data })));
