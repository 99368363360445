import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux";
import { get, set } from "lodash";
import { setSort as storeSetSort, setScrollOffset } from "actions/management";

const ManagementTable = (props) => {
  const { dispatch, scrollOffset } = props;
  const className = ["managment-table", "management-table--" + props.type].join(
    " "
  );
  const [_items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState(props.sortBy);
  const [sortDirection, setSortDirection] = useState(props.sortDirection);

  const setSort = (_sortBy) => {
    if (_sortBy !== sortBy) {
      setSortBy(_sortBy);
    } else {
      setSortDirection(sortDirection === "ASC" ? "DESC" : "ASC");
    }
  };

  useEffect(() => {
    // console.log("storeSetSort", {sortBy, sortDirection})
    dispatch(storeSetSort(sortBy, sortDirection));
  }, [dispatch, sortBy, sortDirection]);

  const filterItems = (
    items,
    query,
    sortBy = "id",
    sortDirection = "ASC",
    limit = 500
  ) => {
    items = items
      .map((item) => {
        return props.cols
          ? Object.keys(props.cols).reduce(
            (acc, path) => {
              const col = props.cols[path];
              const value = get(item, path);
              // console.log({ col, value });
              set(
                acc,
                path.replace(".", "__"),
                ![null, undefined].includes(value) ? (col.render ? col.render(value) : value) : "-"
              );

              // console.log({ acc });
              return acc;
            },
            { id: item.id }
          )
          : {};
      })
      .filter((item) => {
        if (!query || query === "") {
          return true;
        }
        const regExp = new RegExp(
          query.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&"),
          "ig"
        );
        return Object.keys(item).reduce((acc, key) => {
          // console.log(item[key]);
          if (typeof item[key] === "string" && item[key].match(regExp)) {
            return true;
          }
          return acc;
        }, false);
      })
      .sort((a, b) => {
        // console.log(
        //   { sortBy, sortDirection },
        //   get(a, sortBy.replace(".", "__")),
        //   get(b, sortBy.replace(".", "__"))
        // );
        return sortDirection === "ASC"
          ? get(a, sortBy.replace(".", "__")) >
            get(b, sortBy.replace(".", "__"))
            ? 1
            : -1
          : get(a, sortBy.replace(".", "__")) >
            get(b, sortBy.replace(".", "__"))
            ? -1
            : 1;
      })
      .slice(0, limit);
    setItems(items);
  };

  const { type, query, items, requestItems } = props;

  const cacheTypes = [
    "courses",
    "course_categories",
    "textblocks",
    "textblock_categories",
  ];
  const fetchItems = () => {
    setLoading(true);
    dispatch(requestItems())
      .then(() => setLoading(false))
      .then(scrollToOffset);
  };

  // refresh only
  useEffect(() => {
    if (!cacheTypes.includes(type)) {
      fetchItems();
    }
  }, []);

  // only fetch when empty
  useEffect(() => {
    if (cacheTypes.includes(type) && !loading && items.length === 0) {
      fetchItems();
    }
  }, [type, cacheTypes, fetchItems, loading, items]);

  useEffect(() => {
    filterItems(items, query, sortBy, sortDirection);
  }, [query, items, sortBy, sortDirection]);

  const onClickItem = (item) => {
    dispatch(setScrollOffset(table.current.scrollTop))
    dispatch(routerActions.push("/management/" + props.type + "/" + item.id));
  };

  const table = useRef(null)

  const scrollToOffset = () => {
    if (table.current && scrollOffset) {
      setTimeout(() => {
        table.current.scrollTop = scrollOffset
      }, 0)
    }
  }

  useEffect(scrollToOffset, [])

  return (
    <div ref={table} id="table" className={className + " bg-white h-screen overflow-y-auto pb-28"}>
      {loading && (
        <div className="w-full h-full flex flex-col justify-center items-center">
          <i className="fas fa-circle-notch fa-spin fa-3x text-gray-300 p-4" />
          <span className="text-gray-500">
            Einen Moment, die Inhalte werden geladen.
          </span>
        </div>
      )}
      {!loading && (
        <table className="table-auto w-full text-sm">
          <thead className="shadow">
            <tr className="shadow">
              {props.cols &&
                Object.keys(props.cols).map((path, index) => {
                  return props.cols[path].sortable === true ? (
                    <th
                      className="sticky top-0 bg-gray-50 border-b border-gray-100 px-2 pl-8 py-4"
                      key={index}
                    >
                      <span className="flex justify-between">
                        <button
                          onClick={() => setSort(path)}
                          className="flex-1 text-gray-500 cursor-pointer focus:outline-none text-left uppercase tracking-wider text-xs font-medium"
                        >
                          {props.cols[path].label}
                        </button>
                        <span className="w-2 ml-2 text-gray-500">
                          {sortBy === path && sortDirection === "DESC" && (
                            <i className="fas fa-caret-down" />
                          )}
                          {sortBy === path && sortDirection === "ASC" && (
                            <i className="fas fa-caret-up" />
                          )}
                        </span>
                      </span>
                    </th>
                  ) : (
                    <th
                      className="sticky top-0 bg-gray-50 border-b border-gray-100 px-2 pl-8 py-4 font-medium text-gray-500 cursor-pointer focus:outline-none uppercase tracking-wider text-xs text-left"
                      key={index}
                    >
                      {props.cols[path].label}
                    </th>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {_items.map((item, rowIndex) => {
              return (
                <tr
                  key={rowIndex}
                  onClick={() => onClickItem(item)}
                  className="border-t hover:bg-blue-100 hover:text-blue-600 cursor-pointer font-normal"
                >
                  {props.cols &&
                    Object.keys(props.cols).map((path, index) => {
                      const value = get(item, path.replace(".", "__"));
                      return (
                        <td className="px-2 pl-8 py-4" key={index}>
                          {value.toString()}
                        </td>
                      );
                    })}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default connect((state, ownProps) => {
  const items =
    state.entities && state.entities[ownProps.type]
      ? Object.keys(state.entities[ownProps.type]).map((key) => {
        return state.entities[ownProps.type][key];
      })
      : [];
  return {
    items,
    scrollOffset: state.management.scrollOffset,
    query: state.management.query,
    sortBy: state.management.sortBy,
    sortDirection: state.management.sortDirection,
  };
})(ManagementTable);
