import { requestExports } from 'actions/exports'
import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'

function useInterval(callback = () => { }, delay = 1000) {
  const savedCallback = useRef(callback)

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (delay === null) {
      return
    }

    const id = setInterval(() => savedCallback.current(), delay)

    return () => clearInterval(id)
  }, [delay])
}


const ExportsList = (props) => {

  const { exports, dispatch } = props

  useInterval(() => {
    props.dispatch(requestExports())
  }, 1000 * 5)

  useEffect(() => {
    if (!exports) {
      dispatch(requestExports())
    }
  }, [exports, dispatch])

  if (!props.exports) {
    return <div className="p-8 bg-gray-100 h-full flex"><div className="my-auto w-full text-center text-gray-500">Es wurden noch keine Dokumente exportiert.</div></div>
  }

  return <div className="w-full h-full flex flex-col p-8 bg-gray-100 overflow-y-scroll">
    

    <div className="mt-2 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Export
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Dokument
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 pr-4 text-right text-sm font-medium sm:pr-6">
                      Download
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {props.exports.reverse().map((exp, index) => {
                    const createdAt = exp.created_at.split("T")
                    return (
                    <tr key={index}>
                      {/* <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="flex items-center">
                        <div className="ml-4">
                            <div className="font-medium text-gray-900">{exp.id}</div>
                             <div className="text-gray-500">{person.email}</div> 
                          </div>
                        </div>
                      </td> */}
                      <td className=" px-3 py-4 text-sm text-gray-500 pl-4 text-left text-sm sm:pl-6">
                        <div className="text-gray-900 font-medium">{exp.group && exp.group.name}</div>
                        <div className="text-gray-400 text-xs">Export #{exp.id}</div>
                        <div className="text-gray-400 text-xs">{createdAt[0].split("-").reverse().join(".")}</div>
                        <div className="text-gray-400 text-xs">{exp.requesting_user && exp.requesting_user.first_name.split("")[0]}. {exp.requesting_user && exp.requesting_user.last_name}</div>
                      </td>
                      <td className=" px-3 py-4 text-sm text-gray-500">
                        <div className="text-gray-900 font-medium">{exp.document_type && exp.document_type.name}</div>
                        <div className="text-gray-400 text-xs">{exp.document_title && exp.document_title}</div>
                        <div className="text-gray-400 text-xs">{exp.document_year && exp.document_year}</div>
                        <div className="text-gray-400 text-xs">{exp.document_handout_date && exp.document_handout_date.split("T")[0].split("-").reverse().join(".")}</div>
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      {exp.status === 'PENDING' && <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800"><span><i className="far fa-hourglass" /> Ausstehend</span></p>}
                        {exp.status === 'RUNNING' && <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800"><span><i className="far fa-circle-notch fa-spin" /> In Arbeit</span></p>}
                        {/* {exp.status === 'COMPLETED' && <p className="px-2 inline text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"><span><i className="far fa-check" /></span></p>} */}
                        {exp.status === 'FAILED' && <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"><span><i className="far fa-exclamation-triangle" /> Fehler</span></p>}
                        {exp.status === 'ARCHIVED' && <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-400"><span><i className="far fa-archive" /> Archiviert</span></p>}
                        {exp.status === 'COMPLETED' && <div className="block"><a href={`${window.location.origin.replace('.', '-api.')}/api/v1/exports/${exp.id}/document/`} target="_blank" rel="noreferrer" className="text-blue-600 hover:text-blue-900"><i className="far fa-download" />&nbsp; Download</a></div>}
                      </td>
                    </tr>
                  )})}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


{/* 
    <h3 className="text-sm text-gray-400 uppercase text-opacity-75 py-4">Warteschlange &amp; Dokumente Herunterladen</h3>
    {props.exports.reverse().map((exp, index) => {
      const createdAt = exp.created_at.split("T")
      return (
        <div key={index} className="bg-white shadow overflow-hidden sm:rounded-md mt-4">
          <div className="block hover:bg-gray-50">
            <div className="px-4 py-4 sm:px-6">
              <div className="flex items-center justify-between">
                <p className="text-sm font-medium truncate text-gray-600">Export #{exp.id}</p>
                <div className="ml-2 flex-shrink-0 flex">
                  {exp.status === 'PENDING' && <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800"><span><i className="far fa-hourglass" /> Ausstehend</span></p>}
                  {exp.status === 'RUNNING' && <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800"><span><i className="far fa-circle-notch fa-spin" /> In Arbeit</span></p>}
                  {exp.status === 'COMPLETED' && <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"><span><i className="far fa-check" /> Fertig</span></p>}
                  {exp.status === 'FAILED' && <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"><span><i className="far fa-exclamation-triangle" /> Fehler</span></p>}
                  {exp.status === 'ARCHIVED' && <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-400"><span><i className="far fa-archive" /> Archiviert</span></p>}
                </div>
              </div>
              <div>
                <p className="text-xs text-gray-500 py-2">
                  Erstellt am {createdAt[0].split("-").reverse().join(".")} {createdAt[1].split("+")[0]} von {exp.requesting_user && exp.requesting_user.first_name} {exp.requesting_user && exp.requesting_user.last_name}
                </p>
                <dl className="sm:divide-y sm:divide-gray-200 text-xs">
                  <div className="py-2 grid grid-cols-3 gap-4">
                    <dt className="font-medium text-gray-500">Vorlage</dt>
                    <dd className="text-gray-900 col-span-2">{exp.document_type && exp.document_type.name}</dd>
                  </div>
                  <div className="py-2 grid grid-cols-3 gap-4">
                    <dt className="font-medium text-gray-500">Titel</dt>
                    <dd className="text-gray-900 col-span-2">{exp.document_title && exp.document_title}</dd>
                  </div>
                  <div className="py-2 grid grid-cols-3 gap-4">
                    <dt className="font-medium text-gray-500">Schuljahr</dt>
                    <dd className="text-gray-900 col-span-2">{exp.document_year && exp.document_year}</dd>
                  </div>
                  <div className="py-2 grid grid-cols-3 gap-4">
                    <dt className="font-medium text-gray-500">Ausgabe-Datum</dt>
                    <dd className="text-gray-900 col-span-2">{exp.document_handout_date && exp.document_handout_date.split("T")[0].split("-").reverse().join(".")}</dd>
                  </div>
                  <div className="py-2 grid grid-cols-3 gap-4">
                    <dt className="font-medium text-gray-500">Klasse</dt>
                    <dd className="text-gray-900 col-span-2">{exp.group && exp.group.name}</dd>
                  </div>
                </dl>
                {exp.status === 'COMPLETED' && <a href={`${window.location.origin.replace('.', '-api.')}/api/v1/exports/${exp.id}/document/`} target="_blank" rel="noreferrer" className="flex p-2 mt-2 rounded text-sm justify-center items-center bg-blue-600 font-medium text-white w-full focus:outline-none"><i className="far fa-file-certificate" />&nbsp; Herunterladen</a>}
              </div>
            </div>
          </div>
        </div>
      )
    })} */}

  </div>
}

export default connect((state) => {
  const exports = state.entities.exports
    ? Object.keys(state.entities.exports).map(k => state.entities.exports[k])
    : null
  return {
    exports,
  }
})(ExportsList)