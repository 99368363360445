import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { connect } from 'react-redux'
import User from 'components/User'
import Header from 'components/Header'
import Logo from 'components/Logo'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Layout = ({ children, navigation, loading }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <div className="h-screen w-screen flex overflow-hidden bg-white">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex flex-col max-w-xs w-64 bg-blue-700 text-white focus:outline-none">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <i className="fal fa-times h-6 w-6 text-white flex items-center justify-center" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div className="flex-shrink-0 flex items-center px-4">
                  <Header />
                </div>
                <nav aria-label="Sidebar" className="bg-blue-700 mt-5">
                  <div className="px-2 space-y-1">
                    {navigation && navigation.map((section, index) => (
                      <div key={index}>
                        {section.headline &&
                          <h3 className="text-opacity-60 border- border-white border-opacity-25 text-xs py-3 pb-2 pt-8 font-medium tracking-wider text-white">
                            {section.headline}
                          </h3>
                        }
                        {section.items.map((item, key) => (
                          <div key={key}>
                            {!item.ratings && 
                              <a
                                key={item.name}
                                href={item.href}
                                className={classNames(
                                  item.current
                                    ? 'bg-blue-800 text-white'
                                    : 'hover:bg-white hover:bg-opacity-10 text-white ',
                                  'group flex items-center px-2 py-3 text-xs font-medium rounded-md'
                                )}
                              >
                                {item.icon &&
                                  <i className={classNames(
                                    item.current ? '' : '',
                                    'mr-3 h-6 w-6 text-center text-lg fal fa-' + item.icon
                                  )} />
                                }
                                {item.name}
                              </a>
                            }
                            {item.ratings && 
                              <div className="pb-4">
                                <div className="text-xs font-bold p-2 pt-4">
                                  {item.name}
                                </div>
                                {item.ratings.map((rating) => (
                                  <a
                                    key={rating.name}
                                    href={rating.href}
                                    className={classNames(
                                      rating.current
                                        ? 'bg-blue-800 text-white'
                                        : 'hover:bg-white hover:bg-opacity-10 text-white ',
                                      'group flex items-center px-2 py-3 text-xs font-medium rounded-md'
                                    )}
                                  >
                                    {rating.icon &&
                                      <i className={classNames(
                                        rating.current ? '' : '',
                                        'mr-3 h-6 w-6 text-center text-lg fal fa-' + rating.icon
                                      )} />
                                    }
                                    {rating.name}
                                  </a>
                                ))}
                              </div>
                            }
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </nav>
              </div>
              <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                <User />
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:flex lg:flex-shrink-0">
        <div className="flex flex-col w-60">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex-1 flex flex-col min-h-0 bg-blue-700 border-r border-gray-200 text-white">
            <div className="flex-1 flex flex-col pt-2 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4">
                <Header />
              </div>
              <nav className="mt-0 flex-1" aria-label="Sidebar">
                <div className="px-4">
                  {navigation && navigation.map((section, index) => (
                    <div key={index}>
                      {section.headline &&
                        <h3 className="text-opacity-60 border- border-white border-opacity-25 text-xs py-3 pb-2 pt-8 font-medium tracking-wider text-white">
                          {section.headline}
                        </h3>
                      }
                      {section.items.map((item, key) => (
                        <div key={key}>
                          {!item.ratings && 
                            <a
                              key={item.name}
                              href={item.href}
                              className={classNames(
                                item.current
                                  ? 'bg-blue-800 text-white'
                                  : 'hover:bg-white hover:bg-opacity-10 text-white ',
                                'group flex items-center px-2 py-3 text-xs font-medium rounded-md'
                              )}
                            >
                              {item.icon &&
                                <i className={classNames(
                                  item.current ? '' : '',
                                  'mr-3 h-6 w-6 text-center text-lg fal fa-' + item.icon
                                )} />
                              }
                              {item.name}
                            </a>
                          }
                          {item.ratings && 
                            <div className="">
                              {/* <div className="text-xs font-bold pt-2 pb-2">
                                {item.name}
                              </div> */}
                              {item.ratings.map((rating, key) => (
                                <a
                                  key={key}
                                  href={rating.href}
                                  className={classNames(
                                    rating.current
                                      ? 'bg-blue-800 text-white'
                                      : 'hover:bg-white hover:bg-opacity-10 text-white ',
                                    'group flex items-center px-2 py-3 text-xs font-medium rounded-md'
                                  )}
                                >
                                  {rating.icon &&
                                    <i className={classNames(
                                      rating.current ? '' : '',
                                      'mr-3 h-6 w-6 text-center text-lg fal fa-' + rating.icon
                                    )} />
                                  }
                                  {item.name} - {rating.name}
                                </a>
                              ))}
                            </div>
                          }
                        </div>
                      ))}
                    </div>
                  ))}
                  {loading && 
                    <div className="w-full h-full flex flex-col justify-center items-center">
                      <i className="fas fa-circle-notch fa-spin text-gray-300 p-4" />
                    </div>
                  }
                </div>
              </nav>
            </div>
            <div className="flex-shrink-0 flex border-t border-white border-opacity-25 p-4">
              <User />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col min-w-0 flex-1 overflow-y-auto">
        <div className="lg:hidden">
          <div className="flex items-center justify-between bg-blue-600 text-white px-4 py-1.5">
            <div className="flex justify-center items-center">
              <div className="w-10 h-10 mr-4">
                <Logo />
              </div>
              <div>
                <div className="text-sm font-semibold">
                  ZeitFürSchule
                </div>
                <div className="text-xs">
                  Zeugnisplattform
                </div>
              </div>
            </div>
            <div>
              <button
                type="button"
                className="-mr-3 h-12 w-12 inline-flex items-center justify-center rounded-md hover:text-gray-200 focus:outline-none"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <i className="fal fa-bars h-6 w-6 flex items-center justify-center" />
              </button>
            </div>
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}

export default connect()(Layout)