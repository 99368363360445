import React from 'react'
import ManagementHeader from './ManagementHeader'
import ManagementTable from './ManagementTable'
import { requestCourses } from 'actions/courses'

const CourseList = () => {
  const cols = {
    // 'id': {
    //   label: 'ID',
    //   sortable: true,
    // },
    'name': {
      label: 'Titel',
      sortable: true,
    },
    'course_category.name': {
      label: 'Kurskategorie',
      sortable: true,
    },
    // 'rating_type.name': {
    //   label: 'Bewertungstyp',
    //   sortable: true,
    // },
    'teachers': {
      label: 'Lehrkräfte',
      render: (value) => value && value.length > 0 ? value.map(t => t.code).join(", ") : '-',
    },
    'pupil_ids': {
      label: 'Anzahl SuS',
      render: (value) => value && value.length > 0 ? value.length : '-',
    },
    "child_courses": {
      label: 'Beurteilungsmethoden',
      render: (value) => value && value.length > 0 ? value.length+1 : '1',
    },
  }
  return (
    <div className="management managemant--courses h-full overflow-hidden flex flex-col">
      <div className="flex-initial">
        <ManagementHeader type="courses" title="Kurse" />
      </div>
      <div className="flex-1 h-screen">
        <ManagementTable type="courses" requestItems={requestCourses} cols={cols} />
      </div>
    </div>
  )
}

export default CourseList