import { handleActions } from 'redux-actions'

import {
  setScrollOffsetAction,
  setQueryAction,
  resetQueryAction,
  setSortAction,
  resetSortAction
} from 'actions/management'

const reducer = handleActions(
  {
    [setScrollOffsetAction]: (state, action) => {
      return ({
        ...state,
        ...action.payload,
      })
    },
    [setQueryAction]: (state, action) => {
      return ({
        ...state,
        ...action.payload,
      })
    },
    [resetQueryAction]: (state) => {
      return ({
        ...state,
        context: null,
        query: '',
      })
    },
    [setSortAction]: (state, action) => {
      return ({
        ...state,
        ...action.payload,
      })
    },
    [resetSortAction]: (state) => {
      return ({
        ...state,
        sortBy: 'id',
        sortDirection: 'ASC',
      })
    },
  },
  {
    context: null,
    scrollOffset: 0,
    query: '',
    sortBy: 'id',
    sortDirection: 'ASC',
  }
)

export default reducer
