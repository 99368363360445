import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import { reducer as notifications } from 'react-notification-system-redux';
import entities from './entities'
import authentication from './auth'
import management from './management'

const reducers = combineReducers({
  routing: routerReducer,
  authentication,
  entities,
  notifications,
  management,
})

const rootReducer = (state, action) => {
  let resultState = state
  if (action.type === 'LOGOUT') {
    resultState = undefined
  }
  return reducers(resultState, action)
}

export default rootReducer
