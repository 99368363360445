import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux";
import { isEqual } from "lodash-es";
import {
  requestGroup,
  postGroup as postData,
  deleteGroup as deleteData,
} from "actions/groups";
import Input from "../form/Input";
import SelectGrade from "../form/SelectGrade";
import ManagementSubmit from "./ManagementSubmit";
import IsDirtyHelper from "./IsDirtyHelper";


const GroupForm = (props) => {
  const className = [
    "managment-detail",
    "management-detail--" + props.type,
  ].join(" ");

  const initialData = useMemo(
    () => ({
      id: null,
      name: "",
      name_short: "",
      grade_id: null,
    }),
    []
  );

  const [data, setData] = useState();
  const [original, setOriginal] = useState();
  const [isDirty, setIsDirty] = useState(false);

  const {
    params: { id },
    dispatch,
    group,
  } = props;

  useEffect(() => {
    if (!data && id) {
      dispatch(requestGroup(id));
    }
  }, [id, data, dispatch]);

  useEffect(() => {
    if (!id) {
      setData(initialData);
    }
  }, [id, initialData]);

  useEffect(() => {
    const o = group
      ? {
          id: group.id,
          name: group.name,
          name_short: group.name_short,
          grade_id: group.grade ? group.grade.id : null,
        }
      : initialData;
    setOriginal(o);
    setData({ ...o });
  }, [group, initialData]);

  const setDataProp = (key, value) => {
    const d = {
      ...data,
      [key]: value,
    };
    setData(d);
    setIsDirty(!isEqual(original, d));
  };

  if (!data) {
    return null;
  }

  const _postData = (data) => (dispatch) => {
    return dispatch(postData(data)).then(() => {
      setIsDirty(false);
      setOriginal(data);
    });
    // .catch(() => {
    //   setIsDirty(true)
    // })
  };

  const onClickBack = () => {
    dispatch(routerActions.push("/management/" + props.type));
  };

  const onClickReset = () => {
    setData(initialData);
  };

  return (
    <div className={className + " flex flex-col h-full overflow-hidden"}>
      <div className="md:flex md:items-center md:justify-between px-8 py-5 bg-white shadow-md z-10 border-b border-gray-100">
        <button
          type="button"
          className=" inline-flex p-2 mr-3 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          onClick={onClickBack}
        >
          <i className="fal fa-long-arrow-left" />
        </button>
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate">
            {id ? data.name : "Klasse hinzufügen"}
          </h2>
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <ManagementSubmit
            create={data.id === null}
            isDirty={isDirty}
            data={data}
            post={_postData}
            delete={deleteData}
            onClickBack={onClickBack}
            onClickReset={onClickReset}
          />
        </div>
      </div>

      <div className="management-form flex-1 h-full overflow-auto bg-gray-100 p-12">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="grid grid-cols-3 gap-x-12 gap-y-4 mb-10 pb-10 border-b border-gray-300">
            <div className="col-span-3">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Jahrgang & Klassenbezeichnung</h3>
              <p className="mt-1 max-w-4xl text-sm text-gray-500">
                Geben Sie die Informationen für die Klase ein. Schüler*innen können nur über die Ansicht "Schüler*innen" hinzugefügt werden.
              </p>
            </div>
            <SelectGrade
              value={data.grade_id}
              onChange={(value) => setDataProp("grade_id", value)}
            />
            <Input
              label="Name"
              placeholder="Bsp: 3a"
              value={data.name_short}
              onChange={(value) => setDataProp("name_short", value)}
            />
            <Input
              label="Anzeigename"
              placeholder="Bsp: Klasse 3a"
              value={data.name}
              onChange={(value) => setDataProp("name", value)}
            />
          </div>

          {isDirty && (
            <IsDirtyHelper
              route={props.route}
              original={original}
              data={data}
            />
          )}
        </form>
      </div>
    </div>
  );
};

export default connect((state, ownProps) => {
  const group = state.entities.groups
    ? state.entities.groups[ownProps.params.id]
    : null;
  return {
    type: "groups",
    group,
    groups: state.entities.groups,
  };
})(GroupForm);
