import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux";
import { isEqual } from "lodash-es";
import {
  requestTextblock,
  postTextblock as postData,
  deleteTextblock as deleteData,
} from "actions/textblocks";
import InputInt from "../form/InputInt";
import Textarea from "../form/Textarea";
import MultiselectGrade from "../form/MultiselectGrade";
import MultiselectCourseCategory from "../form/MultiselectCourseCategory";
import SelectTextblockCategory from "../form/SelectTextblockCategory";
import ManagementSubmit from "./ManagementSubmit";
import IsDirtyHelper from "./IsDirtyHelper";

const TextblockForm = (props) => {
  const className = [
    "managment-detail",
    "management-detail--" + props.type,
  ].join(" ");

  const initialData = useMemo(
    () => ({
      id: null,
      text: "",
      rating: 0,
      textblock_category_id: null,
      grade_ids: [],
      course_category_ids: [],
    }),
    []
  );

  const [data, setData] = useState();
  const [original, setOriginal] = useState();
  const [isDirty, setIsDirty] = useState(false);

  const {
    params: { id },
    dispatch,
    textblock,
  } = props;

  useEffect(() => {
    if (!data && id) {
      dispatch(requestTextblock(id));
    }
  }, [id, data, dispatch]);

  useEffect(() => {
    if (!id) {
      setData(initialData);
    }
  }, [id, initialData]);

  useEffect(() => {
    const o = textblock
      ? {
          id: textblock.id,
          text: textblock.text,
          rating: textblock.rating,
          textblock_category_id: textblock.textblock_category
            ? textblock.textblock_category.id
            : null,
          grade_ids: textblock.grade_ids,
          course_category_ids: textblock.course_category_ids,
        }
      : initialData;
    setOriginal(o);
    setData({ ...o });
  }, [textblock, initialData]);

  const setDataProp = (key, value) => {
    const d = {
      ...data,
      [key]: value,
    };
    setData(d);
    setIsDirty(!isEqual(original, d));
  };

  if (!data) {
    return null;
  }

  const _postData = (data) => (dispatch) => {
    return dispatch(postData(data)).then(() => {
      setIsDirty(false);
      setOriginal(data);
    });
    // .catch(() => {
    //   setIsDirty(true)
    // })
  };

  const onClickBack = () => {
    props.dispatch(routerActions.push("/management/" + props.type));
  };

  const onClickReset = () => {
    const textblock_category_id = data.textblock_category_id
    setData({
      ...initialData,
      textblock_category_id,
    });
  };

  return (
    <div className={className + " flex flex-col h-full overflow-hidden"}>
      <div className="md:flex md:items-center md:justify-between px-8 py-5 bg-white shadow-md z-10 border-b border-gray-100">
        <button
          type="button"
          className=" inline-flex p-2 mr-3 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          onClick={onClickBack}
        >
          <i className="fal fa-long-arrow-left" />
        </button>
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate">
            {id ? "Beurteilungstext bearbeiten" : "Beurteilungstext hinzufügen"}
          </h2>
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <ManagementSubmit
            create={data.id === null}
            isDirty={isDirty}
            data={data}
            post={_postData}
            delete={deleteData}
            onClickBack={onClickBack}
            onClickReset={onClickReset}
          />
        </div>
      </div>

      <div className="management-form flex-1 h-full overflow-auto bg-gray-100 p-12">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="grid grid-cols-2 gap-12 mb-8">
            <SelectTextblockCategory
              value={data.textblock_category_id}
              onChange={(value) => setDataProp("textblock_category_id", value)}
            />
            <InputInt
              label="Bewertung"
              placeholder="0"
              nullable={true}
              value={data.rating}
              onChange={(value) => setDataProp("rating", value)}
            />
          </div>

          <div className="mb-8">
            <Textarea
              label="Text"
              value={data.text}
              onChange={(value) => setDataProp("text", value)}
            />
          </div>

          <div className="mb-8">
            <MultiselectGrade
              value={data.grade_ids}
              onChange={(value) => setDataProp("grade_ids", value)}
            />
          </div>

          <div className="mb-8">
            <MultiselectCourseCategory
              value={data.course_category_ids}
              onChange={(value) => setDataProp("course_category_ids", value)}
            />
          </div>

          {isDirty && (
            <IsDirtyHelper
              route={props.route}
              original={original}
              data={data}
            />
          )}
        </form>
      </div>
    </div>
  );
};

export default connect((state, ownProps) => {
  const textblock = state.entities.textblocks
    ? state.entities.textblocks[ownProps.params.id]
    : null;
  return {
    type: "textblocks",
    textblock,
    textblocks: state.entities.textblocks,
  };
})(TextblockForm);
