import * as realApi from "api";
// import fakeApi from './_fakeApi'
import { receiveEntities } from "actions/entities";

// const useFakeApi = process.env.REACT_APP_USE_MOCK_DATA // window && window.location && window.location.hostname === 'localhost'

const api = realApi; // useFakeApi ? fakeApi : realApi

export const requestGrades = () => async (dispatch) => {
  const payload = await api.requestGrades();
  // console.log({ payload })
  return dispatch(receiveEntities(payload));
};

export const postGrade = (data) => async (dispatch) => {
  const payload = await api.postGrade(data);
  // console.log({ payload })
  return dispatch(receiveEntities(payload));
};
