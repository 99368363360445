import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { requestGrades } from 'actions/grades'
import SelectWrapper from './SelectWrapper'

const SelectGrade = (props) => {
  const { grades, dispatch } = props
  const [init, setInit] = useState(false)

  useEffect(() => {
    if (!init && (!grades || grades.length === 0)) {
      setInit(true)
      dispatch(requestGrades())
    }
  }, [grades, dispatch, init, setInit])

  return (
    <div className="select select--grade">
      <label className="py-2 block text-sm font-medium text-gray-700">Jahrgang</label>
      <SelectWrapper>
        <select
          onChange={(e) => props.onChange(parseInt(e.target.value))}
          value={props.value || ""}
          className="shadow-sm block w-full border-gray-300 rounded-md outline-none border appearance-none"
        >
          <option value="" disabled>Auswählen...</option>
          {props.grades.sort((t1, t2) => {
            return t1.name > t2.name ? 1 : -1
          }).map(grade => {
            return <option
              key={grade.id}
              value={grade.id}
            >
              {grade.name}
            </option>
          })}
        </select>
      </SelectWrapper>
    </div>
  )
}

export default connect((state, ownProps) => {
  const grades = state.entities && state.entities.grades
    ? Object.keys(state.entities.grades).map((key) => {
      return state.entities.grades[key]
    })
    : []
  return {
    grades,
  }
})(SelectGrade)