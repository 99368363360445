import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux";
import { isEqual } from "lodash-es";
import {
  requestCourseCategory,
  postCourseCategory as postData,
  deleteCourseCategory as deleteData,
} from "actions/courses";
import Input from "../form/Input";
import InputInt from "../form/InputInt";
import SelectExportSection from "../form/SelectExportSection";
import ManagementSubmit from "./ManagementSubmit";
import IsDirtyHelper from "./IsDirtyHelper";

const CourseCategoryForm = (props) => {
  const className = [
    "managment-detail",
    "management-detail--" + props.type,
  ].join(" ");

  const initialData = useMemo(
    () => ({
      id: null,
      name: "",
      name_short: "",
      export_order: 0,
      export_section_id: null,
      course_ids: [],
    }),
    []
  );

  const [data, setData] = useState();
  const [original, setOriginal] = useState();
  const [isDirty, setIsDirty] = useState(false);

  const {
    params: { id },
    dispatch,
    courseCategory,
  } = props;

  useEffect(() => {
    if (!data && id) {
      dispatch(requestCourseCategory(id));
    }
  }, [id, data, dispatch]);

  useEffect(() => {
    if (!id) {
      setData(initialData);
    }
  }, [id, initialData]);

  useEffect(() => {
    const o = courseCategory
      ? {
          id: courseCategory.id,
          name: courseCategory.name,
          name_short: courseCategory.name_short,
          export_order: courseCategory.export_order,
          export_section_id: courseCategory.export_section
            ? courseCategory.export_section.id
            : null,
          course_ids: courseCategory.course_ids,
        }
      : initialData;
    setOriginal(o);
    setData({ ...o });
  }, [courseCategory, initialData]);

  const setDataProp = (key, value) => {
    const d = {
      ...data,
      [key]: value,
    };
    setData(d);
    setIsDirty(!isEqual(original, d));
  };

  if (!data) {
    return null;
  }

  const _postData = (data) => (dispatch) => {
    return dispatch(postData(data)).then(() => {
      setIsDirty(false);
      setOriginal(data);
    });
    // .catch(() => {
    //   setIsDirty(true)
    // })
  };

  const onClickBack = () => {
    dispatch(routerActions.push("/management/" + props.type));
  };

  const onClickReset = () => {
    setData(initialData);
  };

  return (
    <div className={className + " flex flex-col h-full overflow-hidden"}>
      <div className="md:flex md:items-center md:justify-between px-8 py-5 bg-white shadow-md z-10 border-b border-gray-100">
        <button
          type="button"
          className=" inline-flex p-2 mr-3 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          onClick={onClickBack}
        >
          <i className="fal fa-long-arrow-left" />
        </button>
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate">
            {id ? data.name : "Kurskategorie hinzufügen"}
          </h2>
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <ManagementSubmit
            create={data.id === null}
            isDirty={isDirty}
            data={data}
            post={_postData}
            delete={deleteData}
            onClickBack={onClickBack}
            onClickReset={onClickReset}
          />
        </div>
      </div>

      <div className="management-form flex-1 h-full overflow-auto bg-gray-100 p-12">
        <p className="text-sm text-gray-500 mb-6">
          Kurskategorien dienen zur Gruppierung und Strukturierung von Kursen.
          Sie sind mit Fachbereichen vergleichbar. Textbausteine werden
          Jahrgängen und Kurskategorien zugeordnet. Darüber wird entsprechend
          gesteuert, welche Kurse bzw. Kurskategorien welche Beurteilungstexte
          zur Auswahl bieten.
        </p>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="grid grid-cols-2 gap-12 mb-8">
            <Input
              label="Titel"
              value={data.name}
              placeholder="Bsp: Deutsch"
              onChange={(value) => setDataProp("name", value)}
            />
            <Input
              label="Abkürzung"
              value={data.name_short}
              placeholder="Bsp: De"
              onChange={(value) => setDataProp("name_short", value)}
            />
          </div>
          <div className="grid grid-cols-2 gap-12 mb-8">
            <SelectExportSection
              value={data.export_section_id}
              onChange={(value) => setDataProp("export_section_id", value)}
            />
            <InputInt
              label="Export Reihenfolge"
              placeholder="Bsp: 1"
              value={data.export_order}
              onChange={(value) => setDataProp("export_order", value)}
            />
          </div>

          {isDirty && (
            <IsDirtyHelper
              route={props.route}
              original={original}
              data={data}
            />
          )}
        </form>
      </div>
    </div>
  );
};

export default connect((state, ownProps) => {
  const courseCategory = state.entities.course_categories
    ? state.entities.course_categories[ownProps.params.id]
    : null;
  return {
    type: "course_categories",
    courseCategory,
    courses: state.entities.courses,
  };
})(CourseCategoryForm);
