import 'babel-polyfill'
import 'whatwg-fetch'

import React from 'react'

import { render } from 'react-dom'
import { Provider } from 'react-redux'
import {
  Router,
  Route,
  IndexRedirect,
  hashHistory
} from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'
import { updateLocation } from 'actions/selections'

import App from 'containers/App'

import Exports from 'pages/Exports'
import Management from 'pages/Management'
import Ratings from 'pages/Ratings'
import RatingsCourse from 'pages/RatingsCourse'

import store from './store'

import GroupList from 'components/management/GroupList'
import GroupForm from 'components/management/GroupForm'
import TeacherList from 'components/management/TeacherList'
import TeacherForm from 'components/management/TeacherForm'
import PupilList from 'components/management/PupilList'
import PupilForm from 'components/management/PupilForm'
import CourseCategoryList from 'components/management/CourseCategoryList'
import CourseCategoryForm from 'components/management/CourseCategoryForm'
import CourseList from 'components/management/CourseList'
import CourseForm from 'components/management/CourseForm'
import TextblockList from 'components/management/TextblockList'
import TextblockForm from 'components/management/TextblockForm'
import TextblockCategoryList from 'components/management/TextblockCategoryList'
import TextblockCategoryForm from 'components/management/TextblockCategoryForm'

import './index.css'

// load data from localstorage
store.dispatch({ type: 'INIT' })

const rootElement = document.getElementById('root')

const history = syncHistoryWithStore(hashHistory, store)

history.listen(location => store.dispatch(updateLocation(location)))

render(
  <Provider store={store}>
    <Router history={history}>
      <Route path="/" component={App}>
        <IndexRedirect to="ratings" />
        <Route path="ratings" component={Ratings}>
          <Route path="course/:id" component={RatingsCourse} />
        </Route>
        <Route path="management" component={Management}>

          <Route path="groups" component={GroupList} />
          <Route path="groups/create" component={GroupForm} />
          <Route path="groups/:id" component={GroupForm} />

          <Route path="teachers" component={TeacherList} />
          <Route path="teachers/create" component={TeacherForm} />
          <Route path="teachers/:id" component={TeacherForm} />

          <Route path="pupils" component={PupilList} />
          <Route path="pupils/create" component={PupilForm} />
          <Route path="pupils/:id" component={PupilForm} />

          <Route path="course_categories" component={CourseCategoryList} />
          <Route path="course_categories/create" component={CourseCategoryForm} />
          <Route path="course_categories/:id" component={CourseCategoryForm} />

          <Route path="courses" component={CourseList} />
          <Route path="courses/create" component={CourseForm} />
          <Route path="courses/:id" component={CourseForm} />

          <Route path="textblock_categories" component={TextblockCategoryList} />
          <Route path="textblock_categories/create" component={TextblockCategoryForm} />
          <Route path="textblock_categories/:id" component={TextblockCategoryForm} />

          <Route path="textblocks" component={TextblockList} />
          <Route path="textblocks/create" component={TextblockForm} />
          <Route path="textblocks/:id" component={TextblockForm} />

          <Route path="exports" component={Exports} />
        </Route>
      </Route>
    </Router>
  </Provider>,
  rootElement,
)
