import React, { Component } from 'react';
import { connect } from 'react-redux'
import { checkAuth, states } from 'actions/authentication'
import AuthSelector from 'selectors/auth'
import Login from 'components/Login'

class Authentication extends Component {

  componentDidMount() {
    this.props.dispatch(checkAuth())
  }

  render() {
    const { authState, children } = this.props
    switch (authState) {
      case states.AUTHENTICATED:
        return children
      case states.NOT_AUTHENTICATED:
      case states.UNKNOWN:
      default:
        return <Login />
    }
  }
}

const mapStateToProps = state => ({
  authState: AuthSelector.getAuthState(state)
})

export default connect(mapStateToProps)(Authentication)
