import React, { createRef, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Rating from 'components/ratings/Rating'
import { allSettingsSelector } from 'selectors/settings'

const RatingsCourseReports = (props) => {

  const { course, pupils, reports, textblocks, textblockCategories, selectedPupil } = props

  const [refs, setRefs] = useState({})

  useEffect(() => {
    setRefs(Object.keys(pupils).reduce((acc, pupilId) => {
      const pupil = pupils[pupilId]
      acc[pupil.id] = createRef()
      return acc
    }, {}))
  }, [setRefs, pupils])

  useEffect(() => {
    if (!selectedPupil || isNaN(selectedPupil.id)) {
      return
    }
    const ref = refs[selectedPupil.id]
    if (!ref || !ref.current) {
      return
    }
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }, [refs, selectedPupil])

  const onChange = (report) => {
    if (props.onChange) {
      // console.log("RatingsCourseReports.onChange",{report})
      props.onChange([
        ...reports.filter(r => r.id !== report.id),
        report,
      ].sort((a, b) => {
        return a.id > b.id ? 1 : -1
        // return a.pupil_id > b.pupil_id ? 1 : -1
        // const pa = pupils.find(p => p.id === a.pupil_id)
        // const pb = pupils.find(p => p.id === b.pupil_id)
        // if (!pa || !pb) {
        //   return 0
        // }
        // return pa.last_name > pb.last_name ? 1 : -1
      }))
    }
  }

  const pupilsByGroup = Object.keys(pupils).reduce((acc, pupilId) => {
    const pupil = pupils[pupilId]
    const { group } = pupil
    if (group) {
      if (!acc[group.id]) {
        acc[group.id] = {
          ...group,
          pupils: [],
        }
      }
      acc[group.id].pupils.push(pupil)
    }
    return acc
  }, {})

  return <div className="">
    {Object.keys(pupilsByGroup)
      .map(groupId => pupilsByGroup[groupId])
      .map(({ id, name, pupils }) => (
        <div key={id}>
          {/* <h2 className="text-sm text-gray-400 uppercase py-4 px-2">
            {name}
          </h2> */}
          {pupils.map((pupil) => {
            const report = reports.find(report => report.pupil_id === pupil.id)
            if (!pupil || !report) {
              return null
            }
            return <div className="px-10 py-14 border-b border-gray-200" key={report.id} ref={refs[pupil.id]}>
              <div className="bg-white mb-8">
                <h3 className="text-2xl leading-6 font-semibold text-gray-900">{pupil.last_name}, {pupil.first_name}</h3>
                <p className="mt-1 text-sm text-gray-500">
                  {pupil.group.name}
                </p>
              </div>
              <Rating
                type={course.rating_type.type}
                ratingMaxLength={course.rating_max_length || props.config.rating_max_length}
                report={report}
                textblocks={textblocks}
                textblockCategories={textblockCategories}
                onChange={onChange}
              />
            </div>
          })}
        </div>
      ))}
  </div>
}

export default connect(state => ({
  config: allSettingsSelector(state),
}))(RatingsCourseReports)