import download from 'downloadjs'

class APIException extends Error {
  constructor(message, status, data) {
    super(message)
    this.message = message
    this.name = 'APIException'
    this.data = data
    this.status = status
  }
}

class Connector {

  async request(url, method, data) {
    const headers = new Headers({
      'content-type': 'application/json',
      'Cache-Control': 'no-cache'
    })
    const options = {
      method: method || 'GET',
      credentials: 'include',
      headers,
    }

    if ((['POST', 'PATCH', 'PUT'].indexOf(method) >= 0) && data) options.body = JSON.stringify(data)
    const resp = await fetch(url, options)
    if (resp.status === 204) return null

    const json = await resp.json()

    if (!resp.ok) {
      const message = json.error || 'Unknown error'
      throw new APIException(`API request failed (status=${resp.status}): ${message}`, resp.status, json.data)
    } else if (resp.status === 204) {
      return null
    }
    return json
  }

  async downloadFile(url) {
    const headers = new Headers({
      // 'lcp-principal': 'test',
      'Cache-Control': 'no-cache',
      Accept: 'application/octet-stream',
    })
    const options = {
      method: 'GET',
      credentials: 'include',
      headers,
    }
    const resp = await fetch(url, options)

    if (!resp.ok) {
      const json = await resp.json()
      const message = json.error || 'Unknown error'
      throw new APIException(`API request failed (status=${resp.status}): ${message}`, resp.status, json.data)
    }

    const blob = await resp.blob()

    let filename = 'Reports.zip'
    if (resp.headers.get('content-disposition')) {
      const contentDisposition = resp.headers.get('content-disposition')
      filename = contentDisposition.substr(contentDisposition.indexOf('=') + 1)
    }

    download(blob, filename)
  }

}

export default new Connector()
